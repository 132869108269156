import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CanAccess } from 'core/components';
import { Actions } from 'core/types/permissions';
import { DocumentType } from 'modules/documents/types/Document';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import VehicleDocumentationTable, {
	VehicleDocumentationTableProps,
	VehicleDocumentationTableType,
} from 'modules/irp/modules/supplements/components/VehicleDocumentationTable';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { useTranslation } from 'react-i18next';
import Permissions from 'types/Permissions';
import SupplementDocumentsUploader from './SupplementDocumentsUploader';

export interface SupplementDocumentsFormProps {
	previousPath: string;
	documentTypes: DocumentType[];
	tableType?: VehicleDocumentationTableType;
	onValidate?: VehicleDocumentationTableProps['onValidate'];
	onSubmit: () => void | Promise<void>;
}
/**
 * This component is a wrapper for VehicleDocumentationTable that includes
 * standard Card title and SupplementStepFooter;
 * Could be modified further to return a specific type of VDT
 */

export default function SupplementDocumentsForm({
	previousPath,
	documentTypes,
	tableType,
	onSubmit,
	onValidate,
}: SupplementDocumentsFormProps) {
	const { t } = useTranslation('irp/supplements');
	const { supplement } = useSupplement();

	return (
		<Box display="flex" flexDirection="column" rowGap={2}>
			<Card>
				<CardContent>
					<Typography variant="h3">{t('documentation.title')}</Typography>
					<VehicleDocumentationTable tableType={tableType} documentTypes={documentTypes} onValidate={onValidate} />

					<CanAccess resource={Permissions.Documents.resource} action={Actions.READ}>
						<Divider />

						<SupplementDocumentsUploader supplementKey={supplement.key} documentTypes={documentTypes} />
					</CanAccess>
				</CardContent>
			</Card>
			<SupplementStepFooter
				nextLabel={t('buttons.next', { ns: 'core' })}
				previousPath={previousPath}
				onNext={onSubmit}
			/>
		</Box>
	);
}
