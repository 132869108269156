import { NestedNavigationItemProps } from 'core/components/NestedNavigationItem';
import { Module } from 'core/types/module';
import { useCurrentUser } from 'modules/admin/modules/users/components/CurrentUserContext';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/prefer-default-export
export function useModuleNavigation(modules: Module[]): NestedNavigationItemProps[] {
	// Filter out only modules that have a nav menu
	const navModules = modules.filter((m) => !!m.nav);

	// Load translations for *all* navigable modules
	const { t } = useTranslation(navModules.map((m) => m.id));
	const { currentUser } = useCurrentUser();

	// Build menu
	return navModules.reduce((acc, module) => {
		if (!module) return acc;

		acc.push({
			id: module.id,
			label: t('title', { ns: module.id }),
			permission: module.permission,
			to: module.routes ? module.routes[0]?.path : module.nav && module.nav[0]?.to,
			currentUser,
			items: (module.nav || []).slice(!module.routes && (module.nav?.length || 0) === 1 ? 1 : 0)?.map((child) => ({
				id: `${module.id}:${child.id}`,
				to: child.to,
				permission: child.permission,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				label: child.label || t(`navbar.${child.id}`, { ns: module.id }),
				isVisible: child.isVisible,
				currentUser,
			})),
		});

		return acc;
	}, [] as NestedNavigationItemProps[]);
}
