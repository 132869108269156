import { ReportProblem, Search } from '@mui/icons-material';
import { Alert, Input, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { Loader } from 'core/components';
import StyledDataGrid from 'core/components/DataGrid';
import ProgressPercentage from 'core/components/ProgressPercentage';
import RestrictedUseDivider from 'core/components/RestrictedUseDivider';
import { useAPI, usePermissions } from 'core/hooks';
import { useRazor } from 'core/providers/RazorProvider';
import { dayjsFromObject } from 'core/services/intl';
import { openToast } from 'core/services/toast';
import { Actions } from 'core/types/permissions';
import { useFormik } from 'formik';
import CarriersService from 'modules/accounts/api/CarriersService';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import SupplementApproveAndInvoice from 'modules/irp/modules/supplements/components/SupplementApproveAndInvoice';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import TermsCheckbox from 'modules/irp/modules/supplements/components/TermsCheckbox';
import { useCarrier } from 'modules/irp/modules/supplements/providers/CarrierProvider';
import { useClient } from 'modules/irp/modules/supplements/providers/ClientProvider';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import Vehicle, { VehicleIncludeFields } from 'modules/irp/modules/vehicles/types/Vehicle';
import RazorPaths from 'modules/razor/paths';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Address, AddressType, PhysicalDeliveryType } from 'types/Address';
import { CarrierContact } from 'types/Carrier';
import ElectronicDeliveryMethod from 'types/ElectronicDeliveryMethod';
import Permissions from 'types/Permissions';
import Program from 'types/Program';
import { SupplementStatus, SupplementSubmitFormFields, SupplementSubmitStatus } from 'types/Supplement';
import * as Yup from 'yup';
import { SupplementContentSkeleton } from './SupplementPageContainer';

export interface SupplementSubmitFormProps<T extends GridValidRowModel> {
	previousPath: string;
	columns: GridColDef<T>[];
	getRows?: () => Promise<T[]>;
	vehicleIncludes?: VehicleIncludeFields;
	filterRows?: (searchValue: string, rows: T[]) => T[];
	noCredentials?: true;
	showEffectiveMonth?: boolean;
	termsAgreement?: boolean;
}

export default function SupplementSubmitForm<T extends GridValidRowModel = Vehicle>({
	previousPath,
	columns,
	getRows,
	vehicleIncludes,
	filterRows,
	noCredentials,
	showEffectiveMonth,
	termsAgreement,
}: SupplementSubmitFormProps<T>) {
	// Hooks
	const { t } = useTranslation(['irp/supplements', 'irp/vehicles']);
	const navigate = useNavigate();
	const { supplement } = useSupplement();
	const { canAccess } = usePermissions();
	const { clientAddresses } = useClient();
	const { carrier } = useCarrier();
	const { accountKey } = useRazor();

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [vehiclesLoading, setVehiclesLoading] = useState<boolean>(true);
	const [rows, setRows] = useState<T[]>([]);
	const [preferredContact, setPreferredContact] = useState<CarrierContact | null>(null);
	const [searchValue, setSearchValue] = useState<string>('');
	const [submitStatus, setSubmitStatus] = useState<SupplementSubmitStatus | null>(null);
	const [waitForSubmit, setWaitForSubmit] = useState<boolean>(false);

	// Services
	const supplementsService = useAPI(SupplementsService);
	const carriersService = useAPI(CarriersService);

	const validationSchema = Yup.object().shape({
		submitted: Yup.boolean().required(),
		approved: Yup.boolean(),
		termsCertified: Yup.boolean().isTrue(t('data.validation.form_incomplete', { ns: 'core' })),
	});

	// Form
	const formik = useFormik<SupplementSubmitFormFields>({
		initialValues: { approved: false, termsCertified: !termsAgreement, submitted: true },
		validationSchema,
		onSubmit: (updateFields) => {
			if (!supplement || !accountKey) return undefined;

			// Trigger submit
			setSubmitStatus(null);

			const { submitted } = updateFields;
			return supplementsService.triggerAction(supplement.key, { submitted }).then(() => {
				// Wait for submit status
				setWaitForSubmit(true);
			});
		},
	});

	const handleNext = async () => {
		if (!formik) return undefined;

		const errors = await formik.validateForm();

		if (Object.keys(errors).length > 0) {
			openToast({
				id: 'new-account/supplement-submit-form',
				message: t('data.validation.form_incomplete', { ns: 'core' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	// Computed
	// Memoize the DataGrid to prevent page jump re-renders
	const DataGrid = useMemo(() => StyledDataGrid<T>(), []);

	// Set address based on credentials delivery method
	const address: Address | null = (() => {
		switch (supplement.physicalDelivery?.method?.code) {
			case PhysicalDeliveryType.MailingAddress:
				// Use fleet mailing address if set
				// Otherwise, use the IRP mailing address
				// Otherwise, use the carrier's physical address
				return (
					supplement?.fleet.addresses.mailing ||
					carrier?.addresses?.irp.find((a) => a.type?.code === AddressType.Mailing) ||
					carrier?.addresses?.account.find((a) => a.type?.code === AddressType.Physical) ||
					null
				);
			case PhysicalDeliveryType.BusinessAddress:
				return carrier?.addresses?.irp.find((a) => a.type?.code === AddressType.Physical) || null;
			case PhysicalDeliveryType.PickupAddress:
				return clientAddresses ? clientAddresses[0] : null;
			case PhysicalDeliveryType.OtherAddress:
				return supplement?.physicalDelivery?.address || null;
			default:
				return null;
		}
	})();

	// Filter rows if provided
	const filteredRows = filterRows ? filterRows(searchValue, rows) : defaultFilterRows(searchValue, rows);

	// Columns with failed PRISM checks
	// This overrides the VIN column to include an error icon if the vehicle failed PRISM checks
	const { prismErrorsByVin, prismErrorsByDot } = useMemo(
		() =>
			submitStatus?.prism.results.reduce(
				(acc, { vehicle: v, carrier: c, error }) => {
					if (!error) return acc;

					if (v) acc.prismErrorsByVin[v.vin] = error;
					if (c) acc.prismErrorsByDot[c.usdotOfMcrs] = error;

					return acc;
				},
				{ prismErrorsByVin: {} as Record<string, string>, prismErrorsByDot: {} as Record<string, string> },
			) || { prismErrorsByVin: {}, prismErrorsByDot: {} },
		[submitStatus],
	);

	const columnsWithPrism = columns.map((col) => {
		if (col.field !== 'vin') return col;

		return {
			...col,
			minWidth: submitStatus?.prism.failed ? 210 : col.minWidth,
			valueGetter: ({ row }) => {
				// Sort error'd vehicles to the top
				const error = prismErrorsByDot[row.registration.usdotOfMcrs] || prismErrorsByVin[row.vin];
				return error ? `_${row.vin}` : row.vin;
			},
			renderCell: ({ row }) => {
				const error =
					(prismErrorsByDot[row.registration.usdotOfMcrs] || prismErrorsByVin[row.vin]) &&
					[prismErrorsByDot[row.registration.usdotOfMcrs], prismErrorsByVin[row.vin]].filter((v) => !!v).join('. ');
				return (
					<Typography display="flex" alignItems="center" gap={0.5}>
						{error ? (
							<Tooltip title={error[0].toUpperCase() + error.slice(1)} placement="right">
								<ReportProblem color="error" fontSize="small" />
							</Tooltip>
						) : null}
						{row.vin}
					</Typography>
				);
			},
		} as GridColDef<Vehicle>;
	}) as GridColDef<T>[];

	// Load carrier contact info
	useEffect(() => {
		if (!supplement) return;
		carriersService.getContact(supplement.accountKey, Program.IRP).then(setPreferredContact);
	}, [carriersService, supplement]);

	// Load vehicles
	useEffect(() => {
		// Wait for supplement
		if (!supplement) return;
		setLoading(true);

		const defaultGetRows = async () => {
			if (!supplement) return [];

			const vehicles = await supplementsService.listAllVehicles(
				supplement.key,
				{ weightGroup: true, ...vehicleIncludes },
				{
					onPage: (_, v) => {
						setLoading(false);
						setRows(v as unknown as T[]);
					},
				},
			);

			return vehicles as unknown as T[];
		};

		(getRows || defaultGetRows)()
			.then(setRows)
			.finally(() => {
				setLoading(false);
				setVehiclesLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [supplement]);

	// Poll the supplement status endpoint
	useEffect(() => {
		if (!waitForSubmit || !supplement) return;

		const check = () => {
			supplementsService.getSubmitStatus(supplement.key).then(async (status) => {
				setSubmitStatus(status);

				// If PRISM checks are still processing, check again immediately
				// The backend implements long polling so no timeout is needed
				const { prism } = status;
				if (prism.status === 'STATUS_IN_PROGRESS') return check();

				// No PRISM check has been completed yet
				if (prism.status === 'STATUS_UNSPECIFIED') {
					// Done, cancel the poller
					setWaitForSubmit(false);
					return undefined;
				}

				// PRISM checks are done, get all vehicles from PRISM, display failed ones
				if (prism.failed > 0) {
					supplementsService.getSubmitStatus(supplement.key, { 'prism.results': true }).then(setSubmitStatus);

					// Show toast if submit was clicked (not on fresh reload)
					if (formik.submitCount > 0)
						openToast({
							id: 'supplement-submit-form/prism-failed',
							message: t('submit.submitting.prismFailed', { count: status.prism.failed }),
							severity: 'error',
						});
				}

				// No errors, and the form has been submitted at least once, approve if needed
				if (prism.failed === 0 && formik.submitCount > 0) {
					// If approving, trigger approval now
					const { approved } = formik.values;
					if (approved) await supplementsService.triggerAction(supplement.key, { submitted: false, approved });
				}

				// If no failed vehicles and PRISM check completed, redirect based on status
				if (prism.failed === 0) {
					const { status: suppStatus } = await supplementsService.get(supplement.key);
					const redir =
						suppStatus.code === SupplementStatus.Invoiced
							? RazorPaths.Payments.buildPath({})
							: RazorPaths.Manage.Supplements.buildPath(
									{},
									{ supplementKeyId: supplement.key, accountKeyId: supplement.accountKey },
								);
					navigate(redir, { state: { bypass: true } });
				}

				// Done, cancel the poller
				setWaitForSubmit(false);
				return undefined;
			});
		};

		// Check now
		check();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [waitForSubmit]);

	// Check supplement status once on load
	useEffect(() => {
		if (!supplement) return;
		setLoading(true);

		supplementsService.getSubmitStatus(supplement.key).then((status) => {
			setSubmitStatus(status);

			// Failed vehicles, reload with results
			if (status.prism.failed > 0) {
				supplementsService.getSubmitStatus(supplement.key, { 'prism.results': true }).then(setSubmitStatus);
			}

			// If PRISM checks are still processing, poll for changes
			const { prism } = status;
			if (prism.status === 'STATUS_IN_PROGRESS') setWaitForSubmit(true);
		});
	}, [supplementsService, supplement]);

	// Submitting
	if (waitForSubmit || formik.isSubmitting) return <SupplementSubmitting submitStatus={submitStatus} />;

	// Loading (wait for initial submit status)
	if (loading || submitStatus === null) return SupplementContentSkeleton;

	return (
		<Box display="flex" flexDirection="column" rowGap={2}>
			<Card>
				<CardContent>
					<Box>
						<Typography variant="h3" gutterBottom>
							{t('submit.title')}
						</Typography>
						<Typography>{t('submit.subtitle')}</Typography>
					</Box>

					<Box display="flex" flexDirection="column" mt={3} gap={2}>
						{showEffectiveMonth && (
							<Grid container>
								<Grid item xs={12} sm={3}>
									<Typography variant="subtitle2" gutterBottom>
										{t('supplement.effectiveMonth', { ns: 'data' })}
									</Typography>
									<Typography variant="body2">
										{dayjsFromObject(supplement?.effectiveDate)?.format('MMMM YYYY')}
									</Typography>
								</Grid>
							</Grid>
						)}

						<Grid container>
							<Grid item xs={12} sm={3}>
								<Typography variant="subtitle2" gutterBottom>
									{t('supplement.invoiceDelivery.method.label', { ns: 'data' })}
								</Typography>
								<Typography variant="body2">{supplement?.invoiceDelivery?.method?.displayName}</Typography>
							</Grid>
							{supplement?.invoiceDelivery?.method?.code !== ElectronicDeliveryMethod.DownloadPdf && (
								<Grid item xs={12} sm={3}>
									<Typography variant="subtitle2" gutterBottom>
										{supplement?.invoiceDelivery?.method?.displayName}
									</Typography>
									<Typography variant="body2">{supplement?.invoiceDelivery?.details}</Typography>
								</Grid>
							)}
						</Grid>

						{!noCredentials && (
							<Grid container>
								<Grid item xs={12} sm={3}>
									<Typography variant="subtitle2" gutterBottom>
										{t('supplement.physicalDelivery.method.label', { ns: 'data' })}
									</Typography>
									<Typography variant="body2">{supplement?.physicalDelivery?.method?.displayName}</Typography>
								</Grid>
								{supplement?.physicalDelivery?.method?.code !== ElectronicDeliveryMethod.Email && (
									<Grid item xs={12} sm={3}>
										<Typography variant="subtitle2" gutterBottom>
											{t('data.fields.address', { ns: 'core' })}
										</Typography>
										{address && (
											<>
												<Typography variant="body2">{address.line1}</Typography>
												<Typography variant="body2">{address.line2}</Typography>
												<Typography variant="body2">
													{address.city}, {address.state?.code} {address.postalCode}, {address.country}
												</Typography>
											</>
										)}
									</Grid>
								)}
								{supplement?.physicalDelivery?.method?.code === ElectronicDeliveryMethod.Email && (
									<Grid item xs={12} sm={3}>
										<Typography variant="subtitle2" gutterBottom>
											{t('data.fields.email', { ns: 'core' })}
										</Typography>
										{preferredContact && <Typography variant="body2">{preferredContact.contact.email}</Typography>}
									</Grid>
								)}
							</Grid>
						)}

						{supplement?.invoiceComments && (
							<Grid container columnGap={8}>
								<Grid item xs={12} sm={6}>
									<Typography variant="subtitle2" gutterBottom>
										{t('supplement.invoice_comments', { ns: 'data' })}
									</Typography>
									<Typography variant="body2">{supplement?.invoiceComments}</Typography>
								</Grid>
							</Grid>
						)}
					</Box>

					<Box display="flex" flexDirection="row" justifyContent="space-between" my={4}>
						<Typography variant="h5">{t('details.title', { ns: 'irp/vehicles' })}</Typography>
						<Input
							startAdornment={<Search color="primary" />}
							placeholder={`${t('buttons.search', { ns: 'core' })}...`}
							value={searchValue}
							onChange={(e) => setSearchValue(e.currentTarget.value)}
						/>
					</Box>

					{submitStatus?.prism.failed ? (
						<Alert severity="error" sx={{ mb: 2 }}>
							{t('submit.submitting.prismFailed', { count: submitStatus?.prism.failed })}
						</Alert>
					) : null}

					<DataGrid
						className="striped"
						columns={columnsWithPrism}
						rows={filteredRows}
						loading={vehiclesLoading}
						sortModel={submitStatus?.prism.failed ? [{ field: 'vin', sort: 'asc' }] : undefined}
						disableRowSelectionOnClick
					/>

					{termsAgreement && (
						<TermsCheckbox formik={formik} verbiage={t('submit.terms.verbiage', { ns: 'irp/supplements' })} />
					)}

					<RestrictedUseDivider
						permissions={[
							{
								resource: Permissions.IRP.Supplements.resource,
								action: Permissions.IRP.Supplements.actions.approve,
							},
						]}
					>
						<SupplementApproveAndInvoice
							value={formik.values.approved || false}
							onChange={(v) => formik.setFieldValue('approved', v)}
						/>
					</RestrictedUseDivider>
				</CardContent>
			</Card>

			<SupplementStepFooter
				nextLabel={t('buttons.submit', { ns: 'core' })}
				onNext={handleNext}
				nextDisabled={!canAccess(Permissions.IRP.Supplements.resource, Actions.WRITE)}
				previousPath={previousPath}
			/>
		</Box>
	);
}

function defaultFilterRows<T extends GridValidRowModel = Vehicle>(searchValue: string, rows: T[]) {
	return rows.filter((vehicle) => {
		const { vin, title, unitNumber } = vehicle;
		const search = searchValue.toLowerCase();
		return (
			vin.toLowerCase().includes(search) ||
			title?.number?.toLowerCase().includes(search) ||
			unitNumber.toLowerCase().includes(search)
		);
	});
}

function SupplementSubmitting({ submitStatus }: { submitStatus: SupplementSubmitStatus | null }) {
	// Hooks
	const { t } = useTranslation('irp/supplements');

	// Computed
	const { failed, passed, total } = submitStatus?.prism || { failed: 0, passed: 0, total: 0 };
	const submitProgress = ((failed + passed) / total) * 100 || 1;

	return (
		<Card>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				flexDirection="column"
				gap={2}
				height={875}
				maxHeight="50vh"
			>
				<Loader />
				<Typography variant="h5">{t('submit.submitting.title')}</Typography>
				{total > 0 && (
					<Box display="block" width={2 / 3}>
						<ProgressPercentage progress={submitProgress} />
						<Typography variant="subtitle1" align="center">
							{t('submit.submitting.progress', { count: total, complete: failed + passed })}
						</Typography>
					</Box>
				)}
			</Box>
		</Card>
	);
}
