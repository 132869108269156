import { DialogContentText } from '@mui/material';
import { Loader } from 'core/components';
import ClearFleetError from 'core/components/ClearFleetError';
import Dialog from 'core/components/Dialog';
import { useAPI } from 'core/hooks';
import { useRazor } from 'core/providers/RazorProvider';
import { ApiError } from 'core/services/api';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import Supplement, { SupplementNew, SupplementType } from 'types/Supplement';

type SupplementContextProps<T extends SupplementType | unknown = unknown> = {
	supplement: Supplement<T>;
	reload: () => Promise<Supplement | void>;
};

// Create context
export const SupplementContext = createContext<SupplementContextProps | null | undefined>(undefined);

export interface SupplementProviderProps {
	supplementKey: string;
}

export function SupplementProvider({ supplementKey, children }: PropsWithChildren<SupplementProviderProps>) {
	const { setAccountKey } = useRazor();
	const supplementsService = useAPI(SupplementsService);

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [supplement, setSupplement] = useState<Supplement | null>(null);
	const [error, setError] = useState<ApiError | null>(null);

	// Reload supplement
	const reload = useCallback(async () => {
		setLoading(true);
		setError(null);

		return (
			supplementKey === SupplementNew
				? Promise.resolve()
				: supplementsService.get(supplementKey).then((v) => {
						setSupplement(v);
						if (v.accountKey) setAccountKey(v.accountKey);
						return v;
					})
		)
			.catch(setError)
			.finally(() => setLoading(false));
	}, [setAccountKey, supplementKey, supplementsService]);

	// Load supplement on mount
	useEffect(() => {
		if (!supplementKey) return;
		reload();
	}, [reload, supplementKey]);

	// Memoize context value
	const value = useMemo<SupplementContextProps | null>(() => {
		if (!supplement) return null;
		return { supplement, reload };
	}, [supplement, reload]);

	// If there is an error, display it
	if (error) return <ClearFleetError error={error} />;

	// Loading supplement
	if (loading && !supplement) return <Loader sx={{ flex: 1 }} />;

	// Provide context with supplement
	return <SupplementContext.Provider value={value}>{children}</SupplementContext.Provider>;
}

export function useSupplement<T extends SupplementType | unknown = unknown>(): SupplementContextProps<T> {
	const context = useContext(SupplementContext as React.Context<SupplementContextProps<T>>);
	if (context === undefined) {
		throw new Error('useSupplement must be used within a SupplementProvider');
	}

	return context;
}

export interface SupplementBlockerProps {
	basePath: {
		buildPath: (params: { supplementKey: string }) => string;
	};
	title?: string;
	bodyTexts?: string[];
}

export default function SupplementBlocker({
	basePath,
	title,
	bodyTexts,
	children,
}: PropsWithChildren<SupplementBlockerProps>) {
	// Hooks
	const { t, i18n } = useTranslation('irp/supplements');
	const { supplement } = useSupplement();

	const isWorkflow = (pathname: string) =>
		pathname.startsWith(basePath.buildPath({ supplementKey: supplement?.key || '' }));

	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		if (nextLocation.state?.bypass) return false;
		return isWorkflow(currentLocation.pathname) && !isWorkflow(nextLocation.pathname);
	});

	return (
		<>
			{children}

			<Dialog
				title={title || t('dialogs.close.title')}
				severity="error"
				maxWidth="xs"
				isOpen={blocker.state === 'blocked'}
				setIsOpen={() => blocker.reset && blocker.reset()}
				confirmLabel={t('buttons.close', { ns: 'core' })}
				cancelLabel={t('buttons.stay', { ns: 'core' })}
				onConfirm={() => blocker.proceed && blocker.proceed()}
			>
				{bodyTexts && bodyTexts?.length > 0 ? (
					bodyTexts?.map((text, i) => (
						<DialogContentText key={text} gutterBottom={i < bodyTexts.length - 1}>
							{text}
						</DialogContentText>
					))
				) : (
					<>
						<DialogContentText gutterBottom>
							{i18n.exists('dialogs.close.body1')
								? t('dialogs.close.body1')
								: t('dialogs.close.body1', { ns: 'irp/supplements' })}
						</DialogContentText>
						<DialogContentText>
							{i18n.exists('dialogs.close.body2')
								? t('dialogs.close.body2')
								: t('dialogs.close.body2', { ns: 'irp/supplements' })}
						</DialogContentText>
					</>
				)}
			</Dialog>
		</>
	);
}
