/* eslint-disable max-classes-per-file */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { ToastProps } from 'core/types/toast';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { getUser } from './auth';
import i18n from './i18n';
import { openToast } from './toast';

export interface ApiErrorProps {
	// Quiet prevents the error toast from being displayed
	quiet?: boolean;
}

export type ApiErrorPropsBuilder = () => ApiErrorProps;

export class ApiError extends Error {
	constructor(
		public statusCode: number,
		public code: string,
		public message: string,
		public field?: string,
		public errors?: ApiError[],
		public details?: unknown,
	) {
		super(`${code}: ${message}`);
		this.statusCode = statusCode;
		this.code = code;
		this.message = message;
		this.field = field;
		this.errors = errors;
		this.details = details;
	}
}

export default class ApiService {
	// Axios instance
	private $axios: AxiosInstance;

	// Base URL for API
	protected baseUrl: string;

	constructor(baseURL: string) {
		this.$axios = axios.create({
			baseURL,
		});
		this.baseUrl = baseURL;

		// Use interceptor to add the access token to the
		// request headers at runtime to account for login
		// and session refresh scenarios.
		this.$axios.interceptors.request.use(
			(config) => {
				const user = getUser();
				if (!user) {
					return config;
				}

				config.headers.set('Authorization', `Bearer ${user.access_token}`);
				return config;
			},
			(error) => Promise.reject(error),
		);
	}

	/**
	 *  Sends a GET request to the specified service and path, and returns the response data.
	 *
	 * @param path A string indicating the path to send the request to.
	 * @returns A promise that resolves with the response data.
	 */
	protected async GET<ResponseData>(
		path: string,
		errorProps?: ApiErrorProps | ApiErrorPropsBuilder,
		params?: AxiosRequestConfig,
	): Promise<ResponseData> {
		const { data } = await this.$axios
			.get<ResponseData>(this.buildUrl(path), params)
			.catch(this.handleError(errorProps));

		return data;
	}

	/**
	 * Sends a POST request to the specified service and path with the provided request body, and returns the response data.
	 *
	 * @param path A string indicating the path to send the request to.
	 * @param body The request body to send with the request.
	 * @returns A promise that resolves with the response data.
	 */
	protected async POST<RequestBody, ResponseData>(
		path: string,
		body: RequestBody,
		config?: AxiosRequestConfig<RequestBody>,
		errorProps?: ApiErrorProps | ApiErrorPropsBuilder,
	): Promise<ResponseData> {
		const { data } = await this.$axios
			.post<ResponseData>(this.buildUrl(path), body, config)
			.catch(this.handleError(errorProps));
		return data;
	}

	/**
	 * Sends a PUT request to the specified service and path with the provided request body, and returns the response data.
	 *
	 * @param path A string indicating the path to send the request to.
	 * @param body The request body to send with the request.
	 * @returns A promise that resolves with the response data.
	 */
	protected async PUT<RequestBody, ResponseData>(
		path: string,
		body?: RequestBody,
		errorProps?: ApiErrorProps | ApiErrorPropsBuilder,
	): Promise<ResponseData> {
		const { data } = await this.$axios.put<ResponseData>(this.buildUrl(path), body).catch(this.handleError(errorProps));
		return data;
	}

	/**
	 * Sends a PATCH request to the specified service and path with the provided request body, and returns the response data.
	 *
	 * @param path A string indicating the path to send the request to.
	 * @param body The request body to send with the request.
	 * @returns A promise that resolves with the response data.
	 */
	protected async PATCH<RequestBody, ResponseData>(
		path: string,
		body?: RequestBody,
		errorProps?: ApiErrorProps | ApiErrorPropsBuilder,
	): Promise<ResponseData> {
		const { data } = await this.$axios
			.patch<ResponseData>(this.buildUrl(path), body)
			.catch(this.handleError(errorProps));
		return data;
	}

	/**
	 * Sends a DELETE request to the specified service and path with the provided ID.
	 *
	 * @param path A string indicating the path to send the request to.
	 * @param id A string indicating the ID of the item to delete.
	 */
	protected async DELETE(path: string, errorProps?: ApiErrorProps | ApiErrorPropsBuilder): Promise<void> {
		const { data } = await this.$axios.delete(this.buildUrl(path)).catch(this.handleError(errorProps));
		return data;
	}

	// Trim trailing slash from base URL and append path
	private buildUrl(path: string): string {
		const baseUrl = this.baseUrl.replace(/\/$/, '');
		if (!path) return baseUrl;

		return `${baseUrl}/${path.replace(/^\//, '')}`;
	}

	// Error handler
	// eslint-disable-next-line class-methods-use-this
	protected handleError(errorProps?: ApiErrorProps | ApiErrorPropsBuilder) {
		const { quiet } = typeof errorProps === 'function' ? errorProps() : errorProps || {};

		const getError = (e: AxiosError<ApiError>) => {
			if (e.response?.data.message) {
				let s = e.response.data.message;

				// Single validation error, show that
				if (e.response.data.errors && e.response.data.errors.length === 1) {
					s = e.response.data.errors[0].message;
				}

				return s && s[0].toUpperCase() + s.slice(1);
			}
			return null;
		};

		const maybeToast = (props: ToastProps) => {
			if (quiet) return;
			openToast(props);
		};

		return (e: Error) => {
			// API errors
			if (e instanceof AxiosError) {
				const status = e.response?.status || 500;
				switch (status) {
					case 500:
						maybeToast({
							id: `api:${status}`,
							severity: 'error',
							message: getError(e) || i18n.t('errors.500.message', { ns: 'core' }),
						});
						break;
					case 400:
						// Validation errors, show multiple toasts if returned
						if (e.response?.data.errors) {
							e.response.data.errors.forEach((err: ApiError) => {
								maybeToast({
									id: `api:${status}:${err.code}`,
									severity: 'error',
									message: err.message[0].toUpperCase() + err.message.slice(1),
								});
							});
						} else if (e.response?.data.message) {
							const msg = e.response.data.message;
							maybeToast({
								id: `api:${status}:${msg}`,
								severity: 'error',
								message: msg[0].toUpperCase() + msg.slice(1),
							});
						}
						break;
					// Anything else, code errors, etc.
					default:
						maybeToast({
							id: `api:${status ? status.toString() : 'general'}:${getError(e)}`,
							severity: 'error',
							message: getError(e) || `${i18n.t('errors.general.title', { ns: 'core' })}: ${e.response?.statusText}`,
						});
				}

				// Re-throw the error up the stack, but as an ApiError
				const data = e.response?.data || {};
				throw new ApiError(
					e.response?.status || 500,
					data.code,
					getError(e) || data.message,
					data.field,
					data.errors,
					data.details,
				);
			}

			// Re-throw the error up the stack
			throw e;
		};
	}

	// Catch specific error status codes
	// eslint-disable-next-line class-methods-use-this
	protected catchError(statusCode: number, handler: (t: TFunction, error: AxiosError) => void) {
		return (error: AxiosError) => {
			if (error.response?.status === statusCode) {
				handler(i18n.t, error);
				return;
			}

			throw error;
		};
	}
}

// ServerValidation is a Yup test function that can be used to validate
// server-side validation errors returned from the API.
// NOTE: This returns only the first error message for the field.
export function ServerValidation<T>(path: string, field: string, errors: ApiError[]): Yup.TestFunction<T> {
	return (_, context) => {
		const errs = errors.filter((e) => e.field === field);
		if (errs.length === 0) return true;

		return context.createError({
			path,
			type: errs
				.map((e) => e.code)
				// Unique error codes
				.filter((x, i, a) => a.indexOf(x) === i)
				.join(','),
			message: apiErrorMessage(errs),
		});
	};
}

function apiErrorMessage(errs: ApiError[]): string {
	const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1);
	return errs.map((e) => capitalize(e.message)).join('. ');
}
