import { Loader } from 'core/components';
import ClearFleetError, { standardError } from 'core/components/ClearFleetError';
import { useAPI } from 'core/hooks';
import { useRazor } from 'core/providers/RazorProvider';
import UserService from 'modules/admin/modules/users/api/UserService';
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import User, { UserType } from 'types/User';

type CurrentUserContextProps = {
	currentUser: User;
	reload: () => Promise<void>;
};

const CurrentUserContext = createContext<CurrentUserContextProps | undefined>(undefined);

export function CurrentUserProvider({ children }: PropsWithChildren) {
	const userService = useAPI(UserService);
	const { isAuthenticated } = useAuth();
	const { setAccountKey } = useRazor();

	const [currentUser, setCurrentUser] = useState<User>();
	const [error, setError] = useState<Error | null>(null);

	const reload = useCallback(async () => {
		if (!isAuthenticated) return;
		userService.v2.me().then(setCurrentUser).catch(setError);
	}, [isAuthenticated, userService]);

	const value = useMemo<CurrentUserContextProps | undefined>(() => {
		if (!currentUser) return undefined;
		return { currentUser, reload };
	}, [currentUser, reload]);

	// Load user
	useEffect(() => {
		reload();
	}, [reload]);

	// CLEAR-2468: When user is set, check for assigned account
	// If account is assigned, set the Razor account key
	// This is required for pages like Weight Groups or Fleets
	// indexes that use the account key from the Razor provider
	useEffect(() => {
		if (!currentUser || !currentUser.account) return;

		// Only set for carriers
		if (currentUser.type !== UserType.Carrier) return;
		setAccountKey(currentUser.account.key);
	}, [currentUser, setAccountKey]);

	// Error
	if (error) return <ClearFleetError error={error} />;

	// Loading
	if (!currentUser) return <Loader fullScreen />;

	// User is not active
	if (!currentUser.active) return <ClearFleetError error={standardError(401)} />;

	return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>;
}

export function useCurrentUser() {
	const context = useContext(CurrentUserContext);
	if (context === undefined) {
		throw new Error('useCurrentUser must be used within a CurrentUserProvider');
	}

	return context;
}
