import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { CanAccessProps } from 'core/components/CanAccess';
import { usePermissions } from 'core/hooks';
import { useCurrentUser } from 'modules/admin/modules/users/components/CurrentUserContext';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export interface RestrictedUseDividerProps {
	permissions: CanAccessProps[];
}

export default function RestrictedUseDivider({ permissions, children }: PropsWithChildren<RestrictedUseDividerProps>) {
	const { t } = useTranslation();
	const { canAccess } = usePermissions();
	const { currentUser } = useCurrentUser();

	// If the user does not have ANY of the permissions, display nothing
	// Otherwise, if the user has at least ONE of the permissions, display the children
	// NOTE: It is up to the children to check individual permissions, if needed
	if (!permissions.some((p) => canAccess(p.resource, p.action, p.primaryKey))) {
		return null;
	}

	// If the user is seeing this, they have permission, and they are of the correct user type
	return (
		<>
			<Divider>
				<Chip label={t('user.type.use_only', { userType: t(`user.type.${currentUser.type}`) })} />
			</Divider>
			{children}
		</>
	);
}
