import Redirect from 'core/components/Redirect';
import { Route } from 'core/types/routing';
import { FleetProvider } from 'modules/irp/modules/fleets/providers/FleetProvider';
import SupplementPageContainer, {
	SupplementContainerContext,
} from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import NewAccountAddVehicleContainer from 'modules/irp/modules/supplements/modules/new_account/components/NewAccountAddVehicleContainer';
import NewAccountSteps from 'modules/irp/modules/supplements/modules/new_account/components/NewAccountSteps';
import NewAccountSupplementCreator from 'modules/irp/modules/supplements/modules/new_account/components/NewAccountSupplementCreator';
import SupplementBlocker, { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { WeightGroupProvider } from 'modules/irp/modules/weight_groups/providers/WeightGroupProvider';
import { lazy, PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { FleetIncludes } from 'types/Fleet';
import { WeightGroupIncludes, WeightGroupNew } from 'types/WeightGroup';
import NewAccountDetailsStep from './NewAccountDetailsStep';
import NewAccountPaths from './paths';

const NewAccountFleetStep = lazy(() => import('./NewAccountFleetStep'));
const NewAccountFleetDetails = lazy(() => import('./NewAccountFleetDetails'));
const NewAccountFleetJurisdictions = lazy(() => import('./NewAccountFleetJurisdictions'));
const NewAccountFleetInfo = lazy(() => import('./NewAccountFleetInfo'));

const NewAccountWeightGroupsStep = lazy(() => import('./NewAccountWeightGroupsStep'));
const NewAccountWeightGroupDetails = lazy(() => import('./NewAccountWeightGroupDetails'));
const NewAccountWeightGroupInfo = lazy(() => import('./NewAccountWeightGroupInfo'));

const NewAccountVehiclesStep = lazy(() => import('./NewAccountVehiclesStep'));
const NewAccountAddVehicleOptions = lazy(() => import('./NewAccountAddVehicleOptions'));
const NewAccountAddVehicleInfo = lazy(() => import('./NewAccountAddVehicleInfo'));
const NewAccountAddVehicleWeightGroup = lazy(() => import('./NewAccountAddVehicleWeightGroup'));
const NewAccountAddVehicleRegistration = lazy(() => import('./NewAccountAddVehicleRegistration'));

const NewAccountDocumentsStep = lazy(() => import('./NewAccountDocumentsStep'));
const NewAccountVerifyStep = lazy(() => import('./NewAccountVerifyStep'));
const NewAccountSubmitStep = lazy(() => import('./NewAccountSubmitStep'));

// View Vehicle Detail
const VehicleDetailsRoute = lazy(() => import('./NewAccountVehiclesView'));
const VehicleDetailsPlate = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsPlate'));
const VehicleDetailsCredentials = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleDetailsCredentials'),
);
const VehicleDetailsPurchase = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsPurchase'));
const VehicleDetailsDocuments = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsDocuments'));
const VehicleDetailsRegistration = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleDetailsRegistration'),
);

function WeightGroupContainer({ children }: PropsWithChildren) {
	const { supplementKey } = useTypedParams(NewAccountPaths);
	const value = useMemo(
		() => ({ closePath: NewAccountPaths.WeightGroups.buildPath({ supplementKey }) }),
		[supplementKey],
	);

	return <SupplementContainerContext.Provider value={value}>{children}</SupplementContainerContext.Provider>;
}

function WeightGroupProviderFromPath({ children }: PropsWithChildren) {
	const params = useTypedParams(NewAccountPaths.WeightGroups.WeightGroup.Info);
	const include: WeightGroupIncludes = useMemo(() => ({ fleet: true, operatingJurisdictions: true }), []);
	return (
		<WeightGroupProvider weightGroupKey={params.weightGroupKey} include={include}>
			{children}
		</WeightGroupProvider>
	);
}

function NewAccountIndex() {
	const { supplementKey } = useTypedParams(NewAccountPaths);
	return <Redirect to={NewAccountPaths.Registration.buildPath({ supplementKey })} replace />;
}

function WithBlocker({ children }: PropsWithChildren) {
	const { t } = useTranslation('irp/supplements/new_account');
	return (
		<SupplementBlocker
			basePath={NewAccountPaths}
			title={t('dialogs.close.title', { ns: 'irp/supplements/new_account' })}
		>
			{children}
		</SupplementBlocker>
	);
}

function FleetProviderFromSupplement({ children }: PropsWithChildren) {
	const { supplement } = useSupplement();
	const include: FleetIncludes = useMemo(() => ({ mileage: true }), []);
	return (
		<FleetProvider fleetKey={supplement?.fleet?.key || ''} includes={include}>
			{children}
		</FleetProvider>
	);
}

const FleetRoutes: Route[] = [
	// Fleet details
	{
		path: NewAccountPaths.Fleet.path,
		element: (
			<FleetProviderFromSupplement>
				<WithBlocker>
					<Outlet />
				</WithBlocker>
			</FleetProviderFromSupplement>
		),
		handle: {
			crumb: ({ t }) => t('details.title', { ns: 'irp/fleets' }),
		},
		children: [
			{
				index: true,
				path: NewAccountPaths.Fleet.path,
				element: <NewAccountFleetStep />,
			},

			// Fleet information (read-only)
			{
				path: NewAccountPaths.Fleet.Info.path,
				element: <NewAccountFleetInfo />,
				handle: {
					crumb: ({ t }) => t('buttons.view', { ns: 'core' }),
				},
			},
		],
	},

	// Fleet details form
	{
		path: NewAccountPaths.Fleet.Details.path,
		element: (
			<WithBlocker>
				<NewAccountFleetDetails />
			</WithBlocker>
		),
		handle: {
			crumb: ({ t }) => t('details.title', { ns: 'irp/fleets' }),
		},
	},

	// Fleet jurisdictions form
	{
		path: NewAccountPaths.Fleet.Jurisdictions.path,
		element: (
			<WithBlocker>
				<NewAccountFleetJurisdictions />
			</WithBlocker>
		),
		handle: {
			crumb: ({ t }) => t('details.title', { ns: 'irp/fleets' }),
		},
	},
];

const WeightGroupRoutes: Route = {
	path: NewAccountPaths.WeightGroups.path,
	element: <Outlet />,
	handle: {
		// TODO where does this click back to?
		crumb: ({ t }) => t('title', { ns: 'irp/weight_groups' }),
	},
	children: [
		{
			index: true,
			path: NewAccountPaths.WeightGroups.path,
			element: (
				<WithBlocker>
					<NewAccountWeightGroupsStep />
				</WithBlocker>
			),
		},

		// Weight Group add/edit
		{
			path: NewAccountPaths.WeightGroups.WeightGroup.path,
			element: (
				<WeightGroupContainer>
					<NewAccountWeightGroupDetails />
				</WeightGroupContainer>
			),
			handle: {
				crumb: ({ t, params }) =>
					t(`buttons.${params.weightGroupKey === WeightGroupNew ? 'add' : 'edit'}`, { ns: 'core' }),
			},
		} as Route<typeof NewAccountPaths.WeightGroups.WeightGroup>,

		// Weight Group view info
		{
			path: NewAccountPaths.WeightGroups.WeightGroup.Info.path,
			element: (
				<WeightGroupProviderFromPath>
					<NewAccountWeightGroupInfo />
				</WeightGroupProviderFromPath>
			),
			handle: {
				crumb: ({ t }) => t('buttons.view', { ns: 'core' }),
			},
		},
	],
};

const VehicleRoutes: Route = {
	path: NewAccountPaths.Vehicles.path,
	element: <Outlet />,
	handle: {
		crumb: ({ t }) => t('title', { ns: 'irp/vehicles' }),
	},
	children: [
		{
			index: true,
			path: NewAccountPaths.Vehicles.path,
			element: (
				<WithBlocker>
					<NewAccountVehiclesStep />
				</WithBlocker>
			),
		},

		// Vehicle add/edit
		{
			path: NewAccountPaths.Vehicles.Vehicle.path,
			element: (
				<NewAccountAddVehicleContainer>
					<Outlet />
				</NewAccountAddVehicleContainer>
			),
			handle: {
				crumb: ({ t }) => t('details.title', { ns: 'irp/vehicles' }),
			},
			children: [
				// Index route
				{
					path: '',
					index: true,
					element: <NewAccountAddVehicleOptions />,
				},
				{
					path: NewAccountPaths.Vehicles.Vehicle.Info.path,
					element: <NewAccountAddVehicleInfo />,
				},
				{
					path: NewAccountPaths.Vehicles.Vehicle.WeightGroup.path,
					element: <NewAccountAddVehicleWeightGroup />,
				},
				{
					path: NewAccountPaths.Vehicles.Vehicle.Registration.path,
					element: <NewAccountAddVehicleRegistration />,
				},
			],
		},

		// Vehicle View
		{
			path: NewAccountPaths.Vehicles.Vehicle.View.path,
			handle: {
				crumb: ({ t }) => t('buttons.view', { ns: 'core' }),
			},
			element: <VehicleDetailsRoute />,
			children: [
				{
					path: NewAccountPaths.Vehicles.Vehicle.View.path,
					index: true,
					element: <VehicleDetailsPlate />,
				},
				{
					path: NewAccountPaths.Vehicles.Vehicle.View.Credentials.path,
					element: <VehicleDetailsCredentials />,
				},
				{
					path: NewAccountPaths.Vehicles.Vehicle.View.Purchase.path,
					element: <VehicleDetailsPurchase />,
				},
				{
					path: NewAccountPaths.Vehicles.Vehicle.View.Documents.path,
					element: <VehicleDetailsDocuments />,
				},
				{
					path: NewAccountPaths.Vehicles.Vehicle.View.Registration.path,
					element: <VehicleDetailsRegistration />,
				},
			],
		},
	],
};

// Route component mappings
const NewAccountRoutes: Route[] = [
	{
		path: NewAccountPaths.path,
		element: (
			<SupplementPageContainer
				breadcrumbs={[
					'irp/supplements/new_account',
					'irp/supplements',
					'irp/fleets',
					'irp/weight_groups',
					'irp/vehicles',
					'accounts',
				]}
				stepRoutes={[
					NewAccountPaths.Registration.path,
					NewAccountPaths.Fleet.path,
					NewAccountPaths.Fleet.Details.path,
					NewAccountPaths.Fleet.Jurisdictions.path,
					NewAccountPaths.WeightGroups.path,
					NewAccountPaths.Vehicles.path,
					NewAccountPaths.Documentation.path,
					NewAccountPaths.Verify.path,
					NewAccountPaths.Submit.path,
				]}
				steps={<NewAccountSteps />}
				creator={<NewAccountSupplementCreator />}
			>
				<Outlet />
			</SupplementPageContainer>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'irp/supplements/new_account' }),
		},
		children: [
			// Index route
			{
				path: NewAccountPaths.path,
				index: true,
				element: <NewAccountIndex />,
			},

			// Registration details
			{
				path: NewAccountPaths.Registration.path,
				element: (
					<WithBlocker>
						<NewAccountDetailsStep />
					</WithBlocker>
				),
			},

			...FleetRoutes,
			WeightGroupRoutes,
			VehicleRoutes,

			// Documentation
			{
				path: NewAccountPaths.Documentation.path,
				element: (
					<WithBlocker>
						<NewAccountDocumentsStep />
					</WithBlocker>
				),
				handle: {
					crumb: ({ t }) => t('documentation.title', { ns: 'irp/supplements' }),
				},
			},

			// Verify
			{
				path: NewAccountPaths.Verify.path,
				element: (
					<WithBlocker>
						<NewAccountVerifyStep />
					</WithBlocker>
				),
				handle: {
					crumb: ({ t }) => t('verify.title', { ns: 'irp/supplements' }),
				},
			},

			// Submit
			{
				path: NewAccountPaths.Submit.path,
				element: (
					<WithBlocker>
						<NewAccountSubmitStep />
					</WithBlocker>
				),
				handle: {
					crumb: ({ t }) => t('submit.title', { ns: 'irp/supplements' }),
				},
			},
		],
	},
];

export default NewAccountRoutes;
