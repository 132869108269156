import { Box, Button, Typography } from '@mui/material';
import { AuthorizedApp, Loader, PermissionsProvider } from 'core/components';
import ClearFleetError from 'core/components/ClearFleetError';
import Layout from 'core/components/Layout';
import { useAPI, useToast } from 'core/hooks';
import { useModuleNavigation } from 'core/hooks/navigation';
import { ReactComponent as IterisLogo } from 'core/images/iteris-logo.svg';
import { RazorProvider } from 'core/providers/RazorProvider';
import { ReactComponent as ClearFleetLogo } from 'images/clearfleet-logo.svg';
import modules from 'modules';
import AccountPaths from 'modules/accounts/routes/paths';
import UserService from 'modules/admin/modules/users/api/UserService';
import { CurrentUserProvider, useCurrentUser } from 'modules/admin/modules/users/components/CurrentUserContext';
import { HelpPath } from 'modules/home/routes/Help';
import permissions from 'modules/home/services/permissions';
import NewAccountPaths from 'modules/irp/modules/supplements/modules/new_account/routes/paths';
import { ClientProvider } from 'modules/irp/modules/supplements/providers/ClientProvider';
import RazorPaths from 'modules/razor/paths';
import { PropsWithChildren, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SupplementNew } from 'types/Supplement';
import { hasProgram } from 'types/User';

export default function App() {
	// Initialize toasts
	useToast();

	return (
		<AuthorizedApp>
			<PermissionsProvider provider={permissions}>
				<RazorProvider>
					<CurrentUserProvider>
						<AppContent />
					</CurrentUserProvider>
				</RazorProvider>
			</PermissionsProvider>
		</AuthorizedApp>
	);
}

function AppContent() {
	// Hooks
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const menuItems = useModuleNavigation(modules);

	// Example API to get user type
	const usersService = useAPI(UserService);
	const getUserType = useMemo(
		() => () =>
			usersService.v2
				.me()
				.then((resp) => t(`user.type.${resp.type}`, { defaultValue: resp.type }))
				.catch(() => {
					if (location.pathname !== '/error') {
						// TODO translate?
						navigate(`/error?error=${encodeURIComponent('Unable to load user type. Please try again.')}`);
					}

					return '';
				}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[], // No dependencies, only want to create this once
	);

	return (
		<Layout
			modules={modules}
			helpRoute={HelpPath.path}
			getUserType={getUserType}
			menuItems={menuItems}
			components={{
				logo: <ClearFleetLogo height={28} />,
				sidebar: {
					footer: (
						<Box display="flex" alignItems="flex-start" flexDirection="column" mt={1} py={1} px={2} rowGap={0.5}>
							<IterisLogo height={21} />
							<Typography color="Background" variant="caption">
								{t('title', { ns: 'core' })} {process.env.REACT_APP_VERSION}
							</Typography>
						</Box>
					),
				},
			}}
		>
			<ClientProvider>
				{/* TODO delay 500ms before showing the loader to prevent quick loader blips for fast responses */}
				<Suspense fallback={<Loader sx={{ flex: 1 }} />}>
					<NeedsAccount>
						<Outlet />
					</NeedsAccount>
				</Suspense>
			</ClientProvider>
		</Layout>
	);
}

// NeedsAccount is a middleware component that checks if the current
// user has a registered account. If not, it will display a message
// indicating that the user needs to have an Account to access ClearFleet.
// The user can navigate to Razor to start the registration process.
function NeedsAccount({ children }: PropsWithChildren) {
	const { t } = useTranslation('home');
	const navigate = useNavigate();
	const { currentUser } = useCurrentUser();
	const location = useLocation();

	if (
		// Has program, render child components/routes
		hasProgram(currentUser) ||
		// User is on the account creation page
		location.pathname === AccountPaths.Create.buildPath({}) ||
		// User is on the new account supplement page
		location.pathname === NewAccountPaths.buildPath({ supplementKey: SupplementNew }) ||
		// User is redirecting out
		location.pathname.startsWith(RazorPaths.buildPath({}))
	)
		return <> {children} </>;

	// Otherwise, show error message
	return (
		<ClearFleetError error={{ title: t('account.incomplete.title'), messages: [t('account.incomplete.message')] }}>
			<Button
				variant="contained"
				color="primary"
				onClick={() => navigate(RazorPaths.Registration.Programs.buildPath({}))}
			>
				{t('account.incomplete.button')}
			</Button>
		</ClearFleetError>
	);
}
