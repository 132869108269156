import Tracking from 'types/Tracking';

export enum UserType {
	Administrator = 'TYPE_ADMINISTRATOR', // legacy
	Jurisdiction = 'TYPE_JURISDICTION',
	ServiceProvider = 'TYPE_SERVICE_PROVIDER',
	Carrier = 'TYPE_CARRIER',
}

export default interface User {
	id: number;
	key: string;
	authUserId: number;
	firstName: string;
	lastName: string;
	email: string;
	title: string;
	phone: string;
	active: boolean;
	roles: string[];
	type: UserType;

	// Optional, the account the User is associated with
	// Only set on Service Provider and Carrier accounts
	account?: {
		id: number;
		key: string;
		number: number;
		name: string;
	};

	tracking?: Tracking;
}

export interface UserFields {
	firstName?: string;
	lastName?: string;
	email?: string;
	title?: string;
	phone?: string;
	active?: boolean;
	type?: UserType;
	roles?: string[];

	// Account number to associate the user with (carrier or service provider)
	account?: {
		key: string;
	};
}

export function hasProgram(user: User): boolean {
	return !!user.account || isAdmin(user);
}

export function isAdmin(user: User): boolean {
	return [UserType.Administrator, UserType.Jurisdiction].includes(user.type);
}
