import { Box, Card, CardContent, Typography } from '@mui/material';
import { CanAccess } from 'core/components';
import TitleDivider from 'core/components/TitleDivider';
import { useAPI, usePermissions, useTitle, useToast } from 'core/hooks';
import { Actions } from 'core/types/permissions';
import CarriersService from 'modules/accounts/api/CarriersService';
import AccountDetailsForm, { AccountDetailsFormProps } from 'modules/accounts/components/AccountDetailsForm';
import AccountFooter from 'modules/accounts/components/AccountFooter';
import { useCurrentUser } from 'modules/admin/modules/users/components/CurrentUserContext';
import { SupplementContentSkeleton } from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import NewAccountPaths from 'modules/irp/modules/supplements/modules/new_account/routes/paths';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import Permissions from 'types/Permissions';
import { SupplementNew } from 'types/Supplement';
import { isAdmin } from 'types/User';
import AccountPaths from './paths';

export default function AccountCreateRoute() {
	// Hooks
	const { t } = useTranslation('accounts');
	const navigate = useNavigate();
	const { openToast } = useToast();
	const [params] = useTypedSearchParams(AccountPaths.Create);
	const { currentUser, reload: reloadCurrentUser } = useCurrentUser();
	const { reload: reloadPermissions } = usePermissions();

	// Services
	const carriersService = useAPI(CarriersService);

	useTitle(`${t('create.title')} - ${t('title', { ns: 'core' })}`);

	// State
	const [formik, setFormik] = useState<Parameters<AccountDetailsFormProps['onFormik']>[0] | null>(null);

	const handlePrevious = () => {
		navigate(AccountPaths.buildPath({}));
	};

	const handleNext = async () => {
		if (!formik) return undefined;

		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: 'accounts/details-form',
				message: t('data.validation.form_incomplete', { ns: 'core' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	// Submit, create account, reload current user (permissions)
	// redirect to create New Account supplement
	const handleSubmit: AccountDetailsFormProps['onSubmit'] = (fields) => {
		return carriersService.create(params.userKey, fields).then(async (carrier) => {
			openToast({
				id: 'accounts/create',
				message: t('toasts.created'),
				severity: 'success',
			});

			// If the user is self-creating an account, reload permissions
			// to get the new account permissions and redirect to the new account supplement
			if (currentUser.key.toLowerCase() === params.userKey.toLowerCase()) {
				await Promise.all([reloadPermissions(), reloadCurrentUser()]);
			}
			navigate(NewAccountPaths.buildPath({ supplementKey: SupplementNew }, { accountKey: carrier.key }));
		});
	};

	// Redirect to the New Account supplement if account is already created (accountKey is provided)
	useEffect(() => {
		if (!params.accountKey) return;
		navigate(NewAccountPaths.buildPath({ supplementKey: SupplementNew }, { accountKey: params.accountKey }));
	}, [params.accountKey, navigate]);

	// If account key is provided, user already has an account
	// Redirect to create New Account supplement
	if (params.accountKey) return SupplementContentSkeleton;

	return (
		<Box display="flex" flexDirection="column" rowGap={2}>
			<Card>
				<CardContent>
					<Typography variant="h3" gutterBottom mb={2}>
						{t('details.title')}
					</Typography>
					<TitleDivider />

					<AccountDetailsForm onFormik={setFormik} onSubmit={handleSubmit} />
				</CardContent>
			</Card>

			<Box mt={2}>
				<AccountFooter
					nextLabel={t('buttons.submit', { ns: 'core' })}
					onPrevious={handlePrevious}
					onNext={handleNext}
				/>
			</Box>
		</Box>
	);
}

export function AccountCreateCanAccess({ children }: PropsWithChildren) {
	const { currentUser } = useCurrentUser();

	// User can create an account if:
	// 1. They are a non-admin (carrier or service provider) WITHOUT an account
	if (!isAdmin(currentUser) && !currentUser.account) return <> {children} </>;

	// 2. They have the necessary permissions
	return (
		<CanAccess resource={Permissions.Accounts.resource} action={Actions.CREATE} showError>
			{children}
		</CanAccess>
	);
}
