import { PermissionsContext, PermissionsService } from 'core/services/permissions';
import { Permission } from 'core/types/permissions';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Loader } from './Loader';

interface PermissionsProviderProps {
	provider: PermissionsService;
}

export default function PermissionsProvider({ children, provider }: PropsWithChildren<PermissionsProviderProps>) {
	const [loading, setLoading] = useState(true);
	const [permissions, setPermissions] = useState<Permission[]>([]);
	const auth = useAuth();
	const value = useMemo(
		() => ({ permissions, reload: provider.refresh.bind(provider), loading }),
		[permissions, provider, loading],
	);

	// Load permissions initially, and subscribe to changes
	useEffect(() => {
		// Wait for authentication
		if (auth.isAuthenticated) {
			setLoading(true);
			provider
				.getPermissions()
				.then(setPermissions)
				.finally(() => setLoading(false));
		}

		provider.subscribe(setPermissions);

		return () => provider.unsubscribe(setPermissions);
	}, [auth.isAuthenticated, provider]);

	// Loading
	if (loading) return <Loader fullScreen />;

	return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
}
