import { Module } from 'core/types/module';
import { Actions } from 'core/types/permissions';
import RazorPaths from 'modules/razor/paths';
import Permissions from 'types/Permissions';
import { hasProgram } from 'types/User';
import AccountsRoutes from './routes';

const AccountsModule: Module = {
	// ID of the module
	id: 'accounts',

	// Navigation bar items
	nav: [
		{
			id: 'account-info',
			label: 'Account Information',
			to: RazorPaths.Manage.Account.path,
		},
		{
			id: 'account-settings',
			label: 'Account Settings',
			to: RazorPaths.Manage.AccountSettings.path,
			permission: { resource: Permissions.Accounts.Flags.resource, action: Actions.READ },
		},
		{
			id: 'correspondence',
			label: 'Correspondence',
			to: RazorPaths.Correspondence.History.path,
			isVisible: ({ currentUser }) => !!currentUser && hasProgram(currentUser),
		},
		{
			id: 'escrow',
			label: 'Escrow',
			to: RazorPaths.Manage.Escrow.path,
			isVisible: ({ currentUser }) => !!currentUser && hasProgram(currentUser),
		},
		{
			id: 'service-providers',
			label: 'Service Providers',
			to: RazorPaths.Manage.ServiceProviders.path,
			permission: { resource: Permissions.Accounts.ServiceProviders.resource, action: Actions.READ },
		},
		{
			id: 'new',
			label: 'Create New Account',
			to: RazorPaths.Login.CreateAccount.buildPath({}, { new: '1' }),
			permission: { resource: Permissions.Accounts.resource, action: Actions.CREATE },
		},
	],

	routes: AccountsRoutes,
};

export default AccountsModule;
