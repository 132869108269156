import { CanAccess } from 'core/components';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import ReportsPageContainer from 'modules/reports/components/ReportsPageContainer';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Permissions from 'types/Permissions';
import ReportPaths from './paths';

const ReportsIndex = lazy(() => import('./ReportsIndex'));

// Route component mappings
const ReportsRoutes: Route<typeof ReportPaths>[] = [
	{
		path: ReportPaths.path,
		element: (
			<CanAccess resource={Permissions.Reports.resource} action={Actions.READ} showError>
				<ReportsPageContainer>
					<Outlet />
				</ReportsPageContainer>
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'reports' }),
		},
		children: [
			// Index route
			{
				path: ReportPaths.path,
				index: true,
				element: <ReportsIndex />,
			},
		],
	},
];

export default ReportsRoutes;
