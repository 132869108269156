import { styled, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from 'core/components/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const BUTTON_WIDTH = 90;

const StyledButton = styled(Button)({ minWidth: BUTTON_WIDTH });
const ProgressButtons = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
	columnGap: theme.spacing(1),
}));

interface TransmittalStepFooterProps {
	previousPath?: string;
	nextLabel?: string;
	onNext: () => void | Promise<void>;
	// Only use nextDisabled if you also provide a reason why it's disabled
	// such as with a tooltip or Alert component
	nextDisabled?: boolean;
	nextTooltip?: string;
}

export default function TransmittalStepFooter({
	nextLabel,
	nextDisabled,
	nextTooltip,
	onNext,
	previousPath,
}: TransmittalStepFooterProps) {
	const { t } = useTranslation('transmittals');
	const navigate = useNavigate();

	return (
		<ProgressButtons>
			{previousPath && (
				<StyledButton variant="outlined" onClick={() => navigate(previousPath)}>
					{t('buttons.previous', { ns: 'core' })}
				</StyledButton>
			)}
			<Tooltip title={nextTooltip}>
				<Box>
					<LoadingButton variant="contained" disabled={nextDisabled} sx={{ minWidth: BUTTON_WIDTH }} onClick={onNext}>
						{nextLabel || t('buttons.next', { ns: 'core' })}
					</LoadingButton>
				</Box>
			</Tooltip>
		</ProgressButtons>
	);
}
