/* eslint-disable no-use-before-define */
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { ListItemButton, useTheme } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { NavbarProps } from 'core/types/navbar';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import User from 'types/User';
import CanAccess, { CanAccessProps } from './CanAccess';

// NestedNavigationItem is a single item in the menu
export type NestedNavigationItemProps = {
	// Unique item ID
	id: string;

	// NestedNavigation item label, displayed in the menu
	label: string;

	// Page to navigate to
	// If child items are present, this is ignored
	to?: string;

	// Permission required to view this item
	permission?: Pick<CanAccessProps, 'resource' | 'action'>;

	// Optional callback to determine if the item is displayed
	// If this is defined, the item will only be displayed if
	// this function returns true.
	isVisible?: NavbarProps['isVisible'];

	// Child items
	items?: NestedNavigationItemProps[];

	// Current user
	currentUser?: User;
};

// NestedNavigationItemRenderProps are the props used internally
// to render a NestedNavigationItem with its children
type NestedNavigationItemRenderProps = {
	// Depth of the item in the tree, used for indentation
	// NOTE: Top-level items have a depth of 0
	depth: number;

	// Click handler for menu items
	onClick: (item: NestedNavigationItemProps) => void;

	// Active item
	active: string | null;
};

// NestedNavigationItem is a single item in the menu, rendered
// recursively with a collapsible list of children, if any.
export function NestedNavigationItem(props: NestedNavigationItemProps & NestedNavigationItemRenderProps) {
	// Hooks
	const theme = useTheme();
	const location = useLocation();

	const { id, label, to, items: children, permission, depth, active, onClick, isVisible, currentUser } = props;
	const items = children?.filter((item) => !item.isVisible || item.isVisible({ currentUser }));

	const isOpen = active === id;
	const isActive = Boolean(to && location.pathname.indexOf(to) === 0);
	const hasChildren = items && items.length > 0;

	const color = theme.navbar?.sidebar?.color || theme.navbar?.color?.inverted || theme.typography.body1.color;

	const output = (
		<>
			<ListItemButton
				component={RouterLink}
				to={to || ''}
				selected={isActive}
				sx={{ ml: depth * 2, py: depth > 0 ? 0.25 : 0.5 }}
				onClick={(e) => {
					if (hasChildren) e.preventDefault();
					onClick({ id, label, to, items, currentUser });
				}}
			>
				<ListItemText
					primary={label}
					primaryTypographyProps={{
						color,
						fontWeight: isActive ? '600' : '400',
					}}
				/>
				{hasChildren && isOpen ? <ArrowDropUp sx={{ color, fontSize: 22 }} /> : null}
				{hasChildren && !isOpen ? <ArrowDropDown sx={{ color, fontSize: 22 }} /> : null}
			</ListItemButton>

			{hasChildren ? (
				<Collapse in={isOpen} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						{items.map((item) => (
							<NestedNavigationItem
								key={item.id}
								depth={depth + 1}
								active={active}
								isVisible={item.isVisible}
								onClick={() => onClick && onClick(item)}
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...item}
							/>
						))}
					</List>
				</Collapse>
			) : null}
		</>
	);

	// Not visible
	if (isVisible && !isVisible({ currentUser })) return null;

	// No permission required
	if (!permission) return output;

	return (
		<CanAccess resource={permission.resource} action={permission.action}>
			{output}
		</CanAccess>
	);
}
