import { Module } from 'core/types/module';
import { Actions } from 'core/types/permissions';
import RazorPaths from 'modules/razor/paths';
import Permissions from 'types/Permissions';
import FleetPaths from './modules/fleets/routes/paths';
import SupplementPaths from './modules/supplements/routes/paths';
import WeightGroupPaths from './modules/weight_groups/routes/paths';
import IrpRoutes from './routes';

const IRPModule: Module = {
	// ID of the module
	id: 'irp',

	// Navigation bar items
	nav: [
		{
			id: 'wip',
			to: RazorPaths.Manage.WorkInProcess.path,
			permission: { resource: Permissions.IRP.Supplements.resource, action: Actions.READ },
		},
		{
			id: 'supplements',
			to: SupplementPaths.path,
			permission: { resource: Permissions.IRP.Supplements.resource, action: Actions.READ },
		},
		{
			id: 'registrations',
			to: RazorPaths.Manage.Credentials.path,
			permission: { resource: Permissions.IRP.resource, action: Actions.READ },
		},
		{
			id: 'vehicles',
			to: RazorPaths.Manage.Vehicles.path,
			permission: { resource: Permissions.IRP.Vehicles.resource, action: Actions.READ },
		},
		{
			id: 'fleets',
			to: FleetPaths.path,
			permission: { resource: Permissions.IRP.Fleets.resource, action: Actions.READ },
		},
		{
			id: 'weight-groups',
			to: WeightGroupPaths.path,
			permission: { resource: Permissions.IRP.WeightGroups.resource, action: Actions.READ },
		},
		{
			id: 'documents',
			to: RazorPaths.Manage.Documents.path,
			permission: { resource: Permissions.Documents.resource, action: Actions.READ },
		},
		{
			id: 'plate-return',
			to: RazorPaths.Inventory.PlateReturn.path,
			permission: { resource: Permissions.IRP.Vehicles.Fields.PlateReturn.resource, action: Actions.UPDATE },
		},
		{
			id: 'error-corrections',
			to: RazorPaths.ErrorCorrections.path,
			permission: { resource: Permissions.IRP.ErrorCorrections.resource, action: Actions.READ },
		},
	],

	// Permission
	permission: { resource: Permissions.IRP.resource, action: Actions.READ },

	routes: IrpRoutes,
};

export default IRPModule;
