import { Box, Card, CardContent, Typography } from '@mui/material';
import TitleDivider from 'core/components/TitleDivider';
import { useAPI, useToast } from 'core/hooks';
import IrpService from 'modules/irp/api/IrpService';
import IrpRegistrationDetailsForm, {
	IrpRegistrationDetailsFormProps,
} from 'modules/irp/components/IrpRegistrationDetailsForm';
import FleetsService from 'modules/irp/modules/fleets/api/FleetsService';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import { useCarrier } from 'modules/irp/modules/supplements/providers/CarrierProvider';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { SupplementType } from 'types/Supplement';
import NewAccountPaths from './paths';

export default function NewAccountDetailsStep() {
	// Hooks
	const { t } = useTranslation(['irp/supplements/new_account', 'accounts']);
	const navigate = useNavigate();
	const irpService = useAPI(IrpService);
	const fleetsService = useAPI(FleetsService);
	const { supplementKey } = useTypedParams(NewAccountPaths);
	const { supplement, reload: reloadSupplement } = useSupplement<SupplementType.NewAccount>();
	const { reload: reloadCarrier } = useCarrier();
	const { openToast } = useToast();

	// State
	const [formik, setFormik] = useState<Parameters<IrpRegistrationDetailsFormProps['onFormik']>[0] | null>(null);

	const handleSubmit: IrpRegistrationDetailsFormProps['onSubmit'] = (fields) => {
		if (!supplement) return Promise.resolve();

		const { id } = supplement.details.newAccount.irpRegistration;

		// if the form isn't altered, update nothing on BE
		if (!formik?.dirty) {
			return navigate(NewAccountPaths.Fleet.Details.buildPath({ supplementKey }));
		}

		return (
			irpService
				.updateRegistration(id, fields)
				.then(() =>
					// Update the fleet contact details and mailing address from the registration details
					fleetsService.update(supplement.fleet?.key || '', {
						contact: fields.contact || undefined,
						addresses: {
							mailing: fields.addresses?.mailing || undefined,
						},
					}),
				)
				// reloadCarrier will return new irp addresses if changed for later steps
				.then(() => Promise.all([reloadCarrier(), reloadSupplement()]))
				.then(() => navigate(NewAccountPaths.Fleet.Details.buildPath({ supplementKey })))
		);
	};

	const handleNext = async () => {
		if (!formik) return undefined;

		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: 'accounts/details-form',
				message: t('data.validation.form_incomplete', { ns: 'core' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	return (
		<Box display="flex" flexDirection="column" rowGap={2}>
			<Card>
				<CardContent>
					<Typography variant="h3" gutterBottom mb={2}>
						{t('registration.title')}
					</Typography>
					<TitleDivider />

					<IrpRegistrationDetailsForm
						irpRegistration={supplement.details.newAccount.irpRegistration}
						onFormik={setFormik}
						onSubmit={handleSubmit}
					/>
				</CardContent>
			</Card>
			<Box mt={2}>
				<SupplementStepFooter nextLabel={t('buttons.next', { ns: 'core' })} onNext={handleNext} />
			</Box>
		</Box>
	);
}
