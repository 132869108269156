import { Box, Grid } from '@mui/material';
import { Breadcrumbs, Loader, PageContainer } from 'core/components';
import { useTitle } from 'core/hooks';
import TransmittalSteps from 'modules/transmittals/components/TransmittalSteps';
import { TransmittalProvider } from 'modules/transmittals/providers/TransmittalProvider';
import TransmittalPaths from 'modules/transmittals/routes/paths';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import Program from 'types/Program';

export default function TransmittalStepsContainer() {
	// Hooks
	const { program, id } = useTypedParams(TransmittalPaths.Program.Edit);
	const { t } = useTranslation('transmittals');
	useTitle(`${t('title')} - ${t('title', { ns: 'core' })}`);

	return (
		<PageContainer>
			<Breadcrumbs ns="transmittals" />

			<Grid container spacing={3} wrap="wrap">
				<Grid item xs={12} xl={9} display="flex" flexDirection="column" rowGap={3}>
					<TransmittalSteps program={`PROGRAM_${program}` as Program} id={id} />

					<Suspense
						fallback={
							<Box display="flex" height={500}>
								<Loader sx={{ flex: 1 }} delay={150} />
							</Box>
						}
					>
						<TransmittalProvider id={id}>
							<Outlet />
						</TransmittalProvider>
					</Suspense>
				</Grid>
			</Grid>
		</PageContainer>
	);
}
