/* eslint-disable max-classes-per-file */
import ApiService from 'core/services/api';
import {
	CreateUserRequest,
	CreateUserResponse,
	PermissionGroups,
	UpdateUserRequest,
	UpdateUserResponse,
	UserRole,
} from 'modules/admin/modules/users/api';
import { GetUserResponse } from 'modules/admin/modules/users/api/GetUser';
import { ListUsersResponse } from 'modules/admin/modules/users/api/ListUsers';
import AccountRoles from 'modules/admin/shared/accountRoles';
import User, { UserFields } from 'types/User';

export default class UserService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/users/v1`);
	}

	async list(limit: number, offset?: number): Promise<ListUsersResponse> {
		const path = `?limit=${limit}&offset=${offset || 0}`;
		const response = await this.GET<ListUsersResponse>(path);
		return response;
	}

	async get(userKeyID: string): Promise<GetUserResponse> {
		const path = `/${encodeURIComponent(userKeyID)}`;
		const response = await this.GET<GetUserResponse>(path);
		return response;
	}

	async create(data: CreateUserRequest): Promise<CreateUserResponse> {
		const response = await this.POST<CreateUserRequest, CreateUserResponse>('', data);
		return response;
	}

	async update(data: UpdateUserRequest): Promise<UpdateUserResponse> {
		const path = `/${data.userKeyId}`;
		const response = await this.PUT<UpdateUserRequest, UpdateUserResponse>(path, data);
		return response;
	}

	async getPermissionGroups(accountRole: AccountRoles): Promise<PermissionGroups> {
		const path = `/permissionGroups?role=${accountRole}`;
		return this.GET<PermissionGroups>(path);
	}

	async updatePermissions(
		accountRole: AccountRoles,
		permissions: { id: number; viewOnly: boolean; fullAccess: boolean }[],
	): Promise<void> {
		const path = `/permissionGroups/${accountRole}`;
		return this.PUT(path, { permissions });
	}

	async getRoles(): Promise<UserRole[]> {
		const path = '/roles';
		const { roles } = await this.GET<{ roles: UserRole[] }>(path);

		return roles;
	}

	// v2 API
	v2 = new UserServiceV2();
}

class UserServiceV2 extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/users`);
	}

	async list(query: string, limit?: number, offset?: number): Promise<ListUsersResponseV2> {
		const response = await this.GET<ListUsersResponseV2>('', undefined, {
			params: { query, limit, offset },
		});
		return response;
	}

	async get(key: string): Promise<User> {
		const path = `/${encodeURIComponent(key)}`;
		const { user } = await this.GET<{ user: User }>(path);
		return user;
	}

	async create(user: UserFields): Promise<User> {
		const resp = await this.POST<{ user: UserFields }, { user: User }>('', {
			user,
		});
		return resp.user;
	}

	async update(key: string, user: UserFields): Promise<void> {
		const path = `/${encodeURIComponent(key)}`;
		return this.PUT<{ user: UserFields }, void>(path, { user });
	}

	async me(): Promise<User> {
		const { user } = await this.GET<{ user: User }>('/me');
		return user;
	}
}

export interface ListUsersResponseV2 {
	users: User[];
	total: number;
}
