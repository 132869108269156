import { dayjsFromObject } from 'core/services/intl';
import dayjs, { Dayjs } from 'dayjs';
import Date, { DateRange, TimeLocation, getTimeLocation, todayPlus30 } from 'types/Date';
import { SupplementType } from 'types/Supplement';

export interface GetMinMaxDatesProps {
	supplementType: SupplementType;
	registrationYear: DateRange;
	effectiveDate?: Date;
	disableRenewalMinDate?: boolean;
}

export interface FCDMinMaxDates {
	fcdMinDate?: Dayjs;
	fcdMaxDate?: Dayjs;
	nonFcdMinDate?: Dayjs;
	nonFcdMaxDate?: Dayjs;
	deleteMinDate?: Dayjs;
	deleteMaxDate?: Dayjs;
	regYearTimeLoc?: TimeLocation;
}

// single source of truth for min/max dates for fcd-related datepickers and validations
export const getFCDMinMaxDates = ({
	supplementType,
	registrationYear,
	effectiveDate,
	disableRenewalMinDate,
}: GetMinMaxDatesProps): FCDMinMaxDates => {
	// constants
	const { startDate, endDate } = registrationYear;
	const djsStartDate = dayjsFromObject(startDate);
	const djsEndDate = dayjsFromObject(endDate);
	const regStartSOM = djsStartDate?.startOf('month');
	const regStartEOM = djsStartDate?.endOf('month');
	const effectiveMonthSOM = dayjsFromObject(effectiveDate)?.startOf('month');
	const renewalMonthSOM = regStartSOM;
	const renewalMonthEOM = regStartEOM;
	const regYearTimeLoc = getTimeLocation(registrationYear);
	const todayPlus30OrRegEnd = dayjsFromObject(endDate)?.isBefore(todayPlus30) ? dayjsFromObject(endDate) : todayPlus30;
	const nonFcdMinDate = dayjs('01/01/1900');
	const farFutureDate = dayjs('01/01/2100');

	// defaults for previous reg years
	const defaultPrevious = {
		fcdMinDate: djsStartDate,
		fcdMaxDate: djsEndDate,
		nonFcdMinDate,
		nonFcdMaxDate: todayPlus30OrRegEnd,
		regYearTimeLoc,
	};

	// defaults for future reg years
	const defaultFuture = {
		fcdMinDate: regStartSOM,
		fcdMaxDate: regStartEOM,
		nonFcdMinDate,
		nonFcdMaxDate: todayPlus30OrRegEnd,
		regYearTimeLoc,
	};

	// defaults for transfers
	const defaultTransfer = {
		fcdMinDate: regStartSOM,
		fcdMaxDate: djsEndDate,
		nonFcdMinDate,
		nonFcdMaxDate: todayPlus30OrRegEnd,
		regYearTimeLoc,
	};

	const defaultNewAccount = {
		fcdMinDate: regStartSOM,
		fcdMaxDate: regStartEOM,
		nonFcdMinDate,
		nonFcdMaxDate: regStartEOM,
		regYearTimeLoc,
	};

	const defaultRenewal = {
		fcdMinDate: disableRenewalMinDate ? nonFcdMinDate : renewalMonthSOM,
		fcdMaxDate: renewalMonthEOM,
		nonFcdMinDate,
		nonFcdMaxDate: renewalMonthEOM,
		regYearTimeLoc,
	};

	const feeCalcDates: Record<SupplementType, Record<TimeLocation, FCDMinMaxDates>> = {
		[SupplementType.AddVehicle]: {
			[TimeLocation.Previous]: defaultPrevious,
			[TimeLocation.Current]: {
				fcdMinDate: regStartSOM,
				fcdMaxDate: todayPlus30OrRegEnd,
				nonFcdMinDate,
				nonFcdMaxDate: todayPlus30OrRegEnd,
			},
			[TimeLocation.Future]: defaultFuture,
		},

		[SupplementType.AddFleet]: {
			[TimeLocation.Previous]: defaultPrevious,
			[TimeLocation.Current]: {
				fcdMinDate: effectiveMonthSOM,
				fcdMaxDate: todayPlus30OrRegEnd,
				nonFcdMinDate,
				nonFcdMaxDate: todayPlus30OrRegEnd,
				regYearTimeLoc,
			},
			[TimeLocation.Future]: defaultFuture,
		},

		[SupplementType.EditVehicle]: {
			[TimeLocation.Previous]: {
				fcdMinDate: nonFcdMinDate,
				fcdMaxDate: farFutureDate,
				nonFcdMinDate,
				nonFcdMaxDate: todayPlus30OrRegEnd,
				regYearTimeLoc,
			},
			[TimeLocation.Current]: {
				fcdMinDate: nonFcdMinDate,
				fcdMaxDate: todayPlus30OrRegEnd,
				nonFcdMinDate,
				nonFcdMaxDate: todayPlus30OrRegEnd,
				regYearTimeLoc,
			},
			[TimeLocation.Future]: defaultFuture,
		},

		[SupplementType.TransferVehicle]: {
			[TimeLocation.Previous]: {
				deleteMinDate: djsStartDate,
				deleteMaxDate: djsEndDate,
				...defaultTransfer,
			},
			[TimeLocation.Current]: {
				deleteMinDate: djsStartDate,
				deleteMaxDate: djsEndDate,
				...defaultTransfer,
			},
			[TimeLocation.Future]: {
				deleteMinDate: regStartSOM,
				deleteMaxDate: regStartEOM,
				...defaultTransfer,
			},
		},

		[SupplementType.Renewal]: {
			[TimeLocation.Previous]: {
				...defaultPrevious,
			},
			[TimeLocation.Current]: defaultRenewal,
			[TimeLocation.Future]: defaultRenewal,
		},

		[SupplementType.NewAccount]: {
			[TimeLocation.Previous]: {
				...defaultNewAccount,
				nonFcdMaxDate: todayPlus30OrRegEnd,
			},
			[TimeLocation.Current]: defaultNewAccount,
			[TimeLocation.Future]: defaultNewAccount,
		},

		// UNUSED SUPPLEMENT TYPES:
		[SupplementType.WeightGroupChange]: {
			[TimeLocation.Previous]: { regYearTimeLoc },
			[TimeLocation.Current]: { regYearTimeLoc },
			[TimeLocation.Future]: { regYearTimeLoc },
		},
		[SupplementType.DeleteVehicle]: {
			[TimeLocation.Previous]: { regYearTimeLoc },
			[TimeLocation.Current]: { regYearTimeLoc },
			[TimeLocation.Future]: { regYearTimeLoc },
		},
		[SupplementType.EditFleet]: {
			[TimeLocation.Previous]: { regYearTimeLoc },
			[TimeLocation.Current]: { regYearTimeLoc },
			[TimeLocation.Future]: { regYearTimeLoc },
		},
	};

	return feeCalcDates[supplementType][regYearTimeLoc];
};
