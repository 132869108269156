import { Grid } from '@mui/material';
import { Breadcrumbs, PageContainer } from 'core/components';
import Redirect from 'core/components/Redirect';
import { Route } from 'core/types/routing';
import RazorPaths from 'modules/razor/paths';
import { lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import { AccountCreateCanAccess } from './AccountCreate';
import AccountPaths from './paths';

const AccountCreateRoute = lazy(() => import('./AccountCreate'));

// Route component mappings
const AccountsRoutes: Route<typeof AccountPaths>[] = [
	{
		path: AccountPaths.path,
		element: (
			<PageContainer>
				<Breadcrumbs ns={['accounts']} />
				<Outlet />
			</PageContainer>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'accounts' }),
		},
		children: [
			// Index route
			{
				path: AccountPaths.path,
				index: true,
				element: <Redirect to={RazorPaths.buildPath({})} />,
			},

			// Create Account
			// NOTE: Permissions are checked conditionally by user type in AccountCreateRoute
			{
				path: AccountPaths.Create.path,
				element: (
					<AccountCreateCanAccess>
						<Grid container spacing={3}>
							<Grid item xs={12} xl={9}>
								<ErrorBoundary fallback={<Redirect to={AccountPaths.buildPath({})} />}>
									<Outlet />
								</ErrorBoundary>
							</Grid>
						</Grid>
					</AccountCreateCanAccess>
				),
				handle: {
					crumb: ({ t }) => t('create.title', { ns: 'accounts' }),
				},
				children: [
					{
						index: true,
						path: AccountPaths.Create.path,
						element: <AccountCreateRoute />,
					},
				],
			},
		],
	},
];

export default AccountsRoutes;
