import ApiService, { ApiErrorProps } from 'core/services/api';
import Vehicle, { VehicleFields, VehicleIncludeFields } from 'modules/irp/modules/vehicles/types/Vehicle';
import LookupValue from 'types/LookupValue';
import Program from 'types/Program';
import { ArtsFields } from './ArtsFields';
import { GetVehicleFieldsRequest } from './GetFields';

export default class VehiclesService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/vehicles`);
	}

	async getByKey(key: string, includes?: VehicleIncludeFields): Promise<Vehicle> {
		const { vehicle } = await this.GET<{ vehicle: Vehicle }>(`/${key}`, undefined, {
			params: {
				include: includes && Object.keys(includes).length > 0 ? Object.keys(includes).join(',') : undefined,
			},
		});
		return vehicle;
	}

	async remove(vehicle: Vehicle) {
		return this.DELETE(`/${vehicle.key}`);
	}

	async getDeleteReasons(): Promise<LookupValue[]> {
		const path = '/config/deleteReasons';
		const { deleteReasons } = await this.GET<{ deleteReasons: LookupValue[] }>(path);

		return deleteReasons;
	}

	async getPlateReturnOptions(accountKey: string): Promise<LookupValue[]> {
		const path = `/config/plateReturns?accountKey=${encodeURIComponent(accountKey)}`;
		const { plateReturnOptions } = await this.GET<{ plateReturnOptions: LookupValue[] }>(path);

		return plateReturnOptions;
	}

	async getTypes(filters?: { weightGroupType?: number; trailerOnly?: boolean }): Promise<LookupValue[]> {
		const path = '/config/types';
		const { types } = await this.GET<{ types: LookupValue[] }>(path, undefined, {
			params: filters,
		});

		return types;
	}

	async getMakes(displayName: string, vehicleType?: LookupValue): Promise<LookupValue[]> {
		const path = '/config/makes';
		const { makes } = await this.GET<{ makes: LookupValue[] }>(path, undefined, {
			params: { displayName, vehicleType: vehicleType?.id },
		});
		return makes;
	}

	async getFuelTypes(): Promise<LookupValue[]> {
		const path = '/config/fuelTypes';
		const { fuelTypes } = await this.GET<{ fuelTypes: LookupValue[] }>(path, undefined, {
			params: { program: Program.IRP },
		});

		return fuelTypes;
	}

	async listFeeCalculationDates(accountKey: string): Promise<LookupValue[]> {
		const path = `/config/feeCalculationDates?accountKey=${encodeURIComponent(accountKey)}`;
		const { feeCalculationDates } = await this.GET<{ feeCalculationDates: LookupValue[] }>(path);

		return feeCalculationDates;
	}

	async getBuyerTypes(): Promise<LookupValue[]> {
		const path = '/config/buyerTypes';
		const { buyerTypes } = await this.GET<{ buyerTypes: LookupValue[] }>(path);

		return buyerTypes;
	}

	async getFields(req: GetVehicleFieldsRequest, opts?: ApiErrorProps): Promise<VehicleFields> {
		const path = '/fields';

		// Discard type
		const { type: _, ...params } = req;
		const { fields } = await this.GET<{ fields: VehicleFields | null }>(path, opts, {
			params,
		});

		return fields || {};
	}

	async searchArts(query: string): Promise<ArtsFields> {
		const path = '/arts';
		const { result } = await this.GET<{ result: ArtsFields }>(path, undefined, {
			params: { query },
		});

		return result;
	}
}
