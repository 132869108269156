import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid, { GridOwnProps } from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { dateFormat, dateFromObject } from 'core/services/intl';
import { CarrierSupplementInfoSkeleton } from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import { useCarrier } from 'modules/irp/modules/supplements/providers/CarrierProvider';
import { useTranslation } from 'react-i18next';
import Program from 'types/Program';
import Supplement from 'types/Supplement';

interface CarrierSupplementInfoProps {
	supplement?: Supplement | null;
	supplementLoading?: boolean;
}

interface Field {
	label: string;
	value?: string | number | React.ReactNode;
	sx?: GridOwnProps['sx'];
}

export default function CarrierSupplementInfo({ supplement, supplementLoading }: CarrierSupplementInfoProps) {
	// Hooks
	const { t } = useTranslation('irp/supplements');
	const theme = useTheme();

	// State
	const { carrier } = useCarrier();

	const irp = carrier?.programStatuses.find((status) => status.program === Program.IRP);
	const ifta = carrier?.programStatuses.find((status) => status.program === Program.IFTA);
	const mdash = <span>&mdash;</span>;

	const carrierFields: Field[] = [
		{ label: t('carrier.dot_number', { ns: 'data' }), value: carrier?.dotNumber },
		{
			label: t('carrier.account_number', { ns: 'data' }),
			value: carrier?.accountNumber,
			sx: {
				gridRow: { xl: 'span 2' },
				alignItems: { xl: 'flex-start' },
			},
		},
		{
			label: t('carrier.status', { ns: 'data' }),
			value: carrier?.status.displayName,
			sx: { justifyContent: { xl: 'flex-end' } },
		},
		{
			label: t('carrier.irp_status', { ns: 'data' }),
			value: irp?.status.displayName || mdash,
			sx: { justifyContent: { xl: 'flex-end' } },
		},
		{
			label: t('carrier.ifta_status', { ns: 'data' }),
			value: ifta?.status.displayName || mdash,
			sx: { justifyContent: { xl: 'flex-end' } },
		},
	];

	const supplementFields: Field[] = [
		{ label: t('supplement.type', { ns: 'data' }), value: supplement?.type.displayName },
		{
			label:
				supplement?.number !== undefined ? t('supplement.number', { ns: 'data' }) : t('supplement.wip', { ns: 'data' }),
			value: supplement?.number !== undefined ? supplement.number : supplement?.wip,
		},
	];

	const fleetFields: Field[] = [
		{ label: t('carrier.registration_year', { ns: 'data' }), value: supplement?.fleet?.endDate.year },
		{ label: t('carrier.fleet_number', { ns: 'data' }), value: supplement?.fleet?.number },
		{
			label: t('carrier.registration_period', { ns: 'data' }),
			value: `${dateFormat(dateFromObject(supplement?.fleet?.startDate))} - ${dateFormat(dateFromObject(supplement?.fleet?.endDate))}`,
			sx: { display: { xl: 'none' } },
		},
	];

	// Loading
	if (!carrier || supplementLoading) return CarrierSupplementInfoSkeleton;

	return (
		<Card sx={{ borderTop: `.5rem solid ${theme.palette.primary.main}` }}>
			<CardContent>
				<Grid container wrap="wrap" alignItems="flex-end">
					<Grid item xs={12} sm={3} xl={12} mb={1}>
						<Typography variant="h5">{carrier?.name}</Typography>
					</Grid>
					<Grid item xs={12} sm={9} xl={12}>
						<Box
							display="grid"
							gridTemplateColumns={{ xs: 'repeat(3, 1fr)', sm: 'repeat(5, 1fr)', xl: 'unset' }}
							gridTemplateRows={{ xl: 'repeat(3, 1fr)' }}
							gridAutoFlow={{ xl: 'column' }}
							columnGap={{ xs: 2, xl: 0 }}
							rowGap={{ xs: 1, xl: 0 }}
						>
							{carrierFields.map(({ label, value, sx }) => (
								<Box key={label} display={{ xl: 'flex' }} alignItems="center" gridRow="auto" sx={sx}>
									<Typography variant="subtitle2">{label}</Typography>
									<Typography variant="subtitle2" display={{ xs: 'none', xl: 'inline-flex' }}>
										{label && ':'}
									</Typography>
									<Typography variant="body2" lineHeight={theme.typography.subtitle2.lineHeight} ml={{ xl: 0.5 }}>
										{value || <>&mdash;</>}
									</Typography>
								</Box>
							))}
						</Box>
					</Grid>
				</Grid>

				{supplement && (
					<>
						<Divider />

						<Grid container>
							<Grid item xs={12} sm={3} xl={12} mb={1}>
								<Typography variant="h5">{t('supplement_details')}</Typography>
							</Grid>
							<Grid item xs={12} sm={9} xl={12}>
								<Box
									display="grid"
									gridTemplateColumns={{ xs: 'repeat(3, minmax(20%, max-content))', sm: 'repeat(5, 1fr)', xl: 'unset' }}
									gridTemplateRows={{ xl: 'repeat(3, 1fr)' }}
									gridAutoFlow={{ xl: 'column' }}
									columnGap={{ xs: 2, xl: 0 }}
									rowGap={{ xs: 1, xl: 0 }}
								>
									{supplementFields.map(({ label, value, sx }) => (
										<Box key={label} display={{ xl: 'flex' }} alignItems="center" sx={sx}>
											<Typography variant="subtitle2">{label}</Typography>
											<Typography variant="subtitle2" display={{ xs: 'none', xl: 'inline-flex' }}>
												{label && ':'}
											</Typography>
											<Typography variant="body2" lineHeight={theme.typography.subtitle2.lineHeight} ml={{ xl: 0.5 }}>
												{value !== undefined ? value : <>&mdash;</>}
											</Typography>
										</Box>
									))}

									{/* Fleet fields, if provided, mobile version */}
									{supplement.fleet &&
										fleetFields.map(({ label, value, sx }) => (
											<Box key={label} display={{ xl: 'none' }} alignItems="center" sx={sx}>
												<Typography variant="subtitle2">{label}</Typography>
												<Typography variant="subtitle2" display={{ xs: 'none', xl: 'inline-flex' }}>
													{label && ':'}
												</Typography>
												<Typography variant="body2" lineHeight={theme.typography.subtitle2.lineHeight} ml={{ xl: 0.5 }}>
													{value}
												</Typography>
											</Box>
										))}
								</Box>
							</Grid>

							{/* Fleet details, desktop */}
							{supplement.fleet && (
								<Grid item xs={12} display={{ xs: 'none', xl: 'flex' }}>
									<Box display="flex" flexDirection="column" flex={1}>
										{fleetFields.map(({ label, value, sx }) => (
											<Box key={label} display="flex" alignItems="center" sx={sx}>
												<Typography variant="subtitle2">{label}</Typography>
												<Typography variant="subtitle2" display={{ xs: 'none', xl: 'inline-flex' }}>
													{label && ':'}
												</Typography>
												<Typography variant="body2" lineHeight={theme.typography.subtitle2.lineHeight} ml={{ xl: 0.5 }}>
													{value}
												</Typography>
											</Box>
										))}
									</Box>

									<Box flex={1} display="flex" flexDirection="column" alignItems="flex-end">
										<Typography variant="subtitle2">{t('carrier.registration_period', { ns: 'data' })}</Typography>

										<Typography variant="subtitle2">
											{t('data.start', { ns: 'core' })}:{' '}
											<Typography variant="body2" display="inline">
												{dateFormat(dateFromObject(supplement.fleet?.startDate))}
											</Typography>
										</Typography>
										<Typography variant="subtitle2">
											{t('data.end', { ns: 'core' })}:{' '}
											<Typography variant="body2" display="inline">
												{dateFormat(dateFromObject(supplement.fleet?.endDate))}
											</Typography>
										</Typography>
									</Box>
								</Grid>
							)}
						</Grid>
					</>
				)}
			</CardContent>
		</Card>
	);
}
