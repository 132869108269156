import { Error as ErrorIcon, RemoveCircle, Report, Warning } from '@mui/icons-material';
import { Box, Button, Typography, styled, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export interface ClearFleetErrorProps {
	// Error to display
	error: Error | CustomError;
}

export interface CustomError {
	title?: string;
	statusCode?: number;
	messages?: string[];
}

export default function ClearFleetError({ error, children }: PropsWithChildren<ClearFleetErrorProps>) {
	const { t, i18n } = useTranslation();
	const theme = useTheme();

	// Determine title
	const title: string = (() => {
		// Object
		if ('title' in error && typeof error.title === 'string') {
			return error.title;
		}

		// API Errors or CustomErrors with statusCode
		if (
			'statusCode' in error &&
			typeof error.statusCode === 'number' &&
			i18n.exists(`errors.${error.statusCode}.title`)
		) {
			return t(`errors.${error.statusCode}.title`);
		}

		// Default
		return t('errors.general.title');
	})();

	// Determine message(s)
	const messages: string[] = (() => {
		const m: string[] = [];
		let isPermissionDenied = false;

		// Object
		if ('messages' in error && error.messages instanceof Array) {
			return error.messages;
		}

		// API Errors or CustomErrors with statusCode
		if (
			'statusCode' in error &&
			typeof error.statusCode === 'number' &&
			i18n.exists(`errors.${error.statusCode}.message`)
		) {
			// 403's return fully qualified error messages, no need to add the default message
			if (error.statusCode === 403) isPermissionDenied = true;

			if (!isPermissionDenied || !('message' in error)) {
				m.push(t(`errors.${error.statusCode}.message`));
			}
		}

		// General message if nothing more specific has been added
		if (m.length === 0 && !isPermissionDenied) {
			m.push(t('errors.general.message'));
		}

		// Single message
		if ('message' in error && typeof error.message === 'string') {
			m.push(error.message);
		}

		// Default
		return m;
	})();

	// Determine icon
	const icon: JSX.Element = (() => {
		const iconStyle = { color: theme.palette.error.main, fontSize: 128 };

		// Status code
		if ('statusCode' in error && typeof error.statusCode === 'number') {
			switch (error.statusCode) {
				case 400:
					return <ErrorIcon sx={iconStyle} />;
				case 403:
					return <RemoveCircle sx={iconStyle} />;
				case 404:
					return <Report sx={iconStyle} />;
				default:
					return <Warning sx={iconStyle} />;
			}
		}

		// Default
		return <Warning sx={iconStyle} />;
	})();

	// Set page title
	document.title = `${title} - ${t('title', { ns: 'core' })}`;

	return (
		<Container>
			{icon}

			<Typography variant="h2">{title}</Typography>

			<Box maxWidth="33vw" my={2}>
				{(messages || []).map((msg) => (
					<Typography key={msg} variant="h5" textAlign="center" lineHeight={1.5} paragraph>
						{msg}
					</Typography>
				))}
			</Box>

			{/* TODO Update this to redirect to React's homepage once we have one */}
			{children || (
				<Button
					variant="contained"
					onClick={() => {
						window.location.href = process.env.REACT_APP_CLEARFLEET_RAZOR_URL || '';
					}}
				>
					{t('buttons.return_home', { ns: 'core' })}
				</Button>
			)}
		</Container>
	);
}

const Container = styled(Box)(({ theme }) => ({
	margin: theme.spacing(2),
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: theme.spacing(3),
}));

export function standardError(errorCode: 400 | 401 | 403 | 404 | 500): CustomError {
	return { statusCode: errorCode };
}
