import { CanAccess } from 'core/components';
import Title from 'core/components/Title';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Permissions from 'types/Permissions';
import UserPaths from './paths';

const UsersIndex = lazy(() => import('./UsersIndex'));
const UserDetail = lazy(() => import('./UserDetail'));
const ManagePermissions = lazy(() => import('./ManagePermissions'));
const ManagePermissionsLegacy = lazy(() => import('./ManagePermissionsLegacy'));

// Route component mappings
const UsersRoutes: Route<typeof UserPaths>[] = [
	{
		path: UserPaths.path,
		element: <Outlet />,
		handle: {
			crumb: ({ t }) => t('title', { ns: 'admin/users' }),
		},
		children: [
			{
				path: UserPaths.path,
				index: true,
				element: (
					<CanAccess resource={Permissions.Admin.Users.resource} action={Actions.READ} showError>
						<UsersIndex />
					</CanAccess>
				),
			},
			{
				path: UserPaths.Create.path,
				handle: {
					crumb: ({ t }) => t('users.create.title', { ns: 'admin/users' }),
				},
				element: (
					<CanAccess resource={Permissions.Admin.Users.resource} action={Actions.CREATE} showError>
						<UserDetail />
					</CanAccess>
				),
			},
			{
				path: UserPaths.User.Edit.path,
				handle: {
					crumb: ({ t }) => t('users.details.title', { ns: 'admin/users' }),
				},
				// NOTE: Permission check is done in UserDetail on a per-user basis
				element: <UserDetail />,
			},
			{
				path: UserPaths.User.path,
				handle: {
					crumb: ({ t }) => t('users.details.title', { ns: 'admin/users' }),
				},
				// NOTE: Permission check is done in UserDetail on a per-user basis
				element: <UserDetail readonly />,
			},

			// Permissions
			{
				path: UserPaths.Permissions.path,
				handle: {
					crumb: ({ t }) => t('permissions.title', { ns: 'admin/users' }),
				},
				element: (
					<CanAccess resource={Permissions.Admin.Permissions.resource} action={Actions.READ} showError>
						<Title
							title={(t) => `${t('permissions.title', { ns: 'admin/users' })} - ${t('title', { ns: 'core' })}`}
							ns={['admin/users']}
						/>
						<Outlet />
					</CanAccess>
				),
				children: [
					{
						path: UserPaths.Permissions.path,
						index: true,
						element: <ManagePermissions />,
					},
					{
						path: UserPaths.Permissions.Legacy.path,
						element: <ManagePermissionsLegacy />,
					},
				],
			},
		],
	},
];

export default UsersRoutes;
