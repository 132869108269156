import { Module } from 'core/types/module';
import { Actions } from 'core/types/permissions';
import Permissions from 'types/Permissions';
import ReportsRoutes from './routes';

const ReportsModule: Module = {
	// ID of the module
	id: 'reports',

	// Navigation bar items
	nav: [],

	// Permission
	permission: { resource: Permissions.Reports.resource, action: Actions.READ },

	// Routes
	routes: ReportsRoutes,
};

export default ReportsModule;
