import { ApiError } from 'core/services/api';
import { PermissionsService } from 'core/services/permissions';
import { Permission, PermissionProvider } from 'core/types/permissions';
import CVOPermissionsService from 'modules/home/services/PermissionsService';

class ClearFleetPermissionsService extends PermissionsService implements PermissionProvider {
	// Retrieve the permissions for the current user
	// This should return *quickly* so we cache the result
	async getPermissions() {
		const permissionsService = new CVOPermissionsService();

		// No permissions or expired cache
		if (this.isExpired()) {
			try {
				const permissions = await permissionsService.get();
				this.setPermissions(permissions);
			} catch (e) {
				// 401 Unauthorized - User is not logged in or session expired
				// Clear the cache and redirect to login
				if (e instanceof ApiError && e.statusCode === 401) {
					window.localStorage.clear();
					window.sessionStorage.clear();
					window.location.reload();
				}

				// Error, no permissions granted
				return this.permissions;
			}
		}

		return this.permissions;
	}

	// Example method to change permissions
	async setPermissions(permissions: Permission[]) {
		super.setPermissions(permissions);
	}
}

export default new ClearFleetPermissionsService();
