import Grid from '@mui/material/Grid';
import { Breadcrumbs, PageContainer } from 'core/components';
import { useTitle } from 'core/hooks';

import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export default function ReportsPageContainer({ children }: PropsWithChildren) {
	// Hooks
	const { t } = useTranslation('reports');
	useTitle(`${t('title')} - ${t('title', { ns: 'core' })}`);

	return (
		<PageContainer>
			<Breadcrumbs ns="reports" />

			<Grid container spacing={3} wrap="wrap">
				<Grid sx={{ order: { xs: 3, xl: 2 } }} item xs={12} xl={9}>
					{children}
				</Grid>

				{/* <Grid sx={{ order: { xs: 1, xl: 3 } }} item xs={12} xl={3}></Grid> */}
			</Grid>
		</PageContainer>
	);
}
