import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { useCurrentUser } from 'modules/admin/modules/users/components/CurrentUserContext';
import { useTranslation } from 'react-i18next';

export interface CertifyCheckboxProps {
	name: string;
	checked: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	label?: string;
	disabled?: boolean;
	error?: boolean;
}
export default function CertifyCheckbox({ name, checked, onChange, label, disabled, error }: CertifyCheckboxProps) {
	const { t } = useTranslation();
	const { currentUser } = useCurrentUser();

	return (
		<FormControl error={error} fullWidth={false} disabled={disabled}>
			<FormControlLabel
				control={<Checkbox name={name} checked={checked} disabled={disabled} onChange={onChange} sx={{ py: 0 }} />}
				label={label || t(`data.certification.${currentUser.type}`)}
			/>
			<FormHelperText sx={{ ml: 4 }}>{t('data.validation.required')}</FormHelperText>
		</FormControl>
	);
}
