import { Action, Actions, Permission } from 'core/types/permissions';
import { ToastProps } from 'core/types/toast';
import { TFunction } from 'i18next';

const all = { [Actions.ALL]: Actions.ALL };
const read = { [Actions.READ]: Actions.READ };
const create = { [Actions.CREATE]: Actions.CREATE };
const update = { [Actions.UPDATE]: Actions.UPDATE };
const write = { [Actions.WRITE]: Actions.WRITE };
const del = { [Actions.DELETE]: Actions.DELETE };

const Permissions = {
	FullAccess: { resource: '*', actions: all },
	Accounts: {
		resource: 'accounts',
		actions: { ...read, ...update, ...create },
		Addresses: {
			Business: {
				resource: 'accounts.addresses.business',
				actions: update,
			},
			Mailing: {
				resource: 'accounts.addresses.mailing',
				actions: write,
			},
		},
		Contact: {
			resource: 'accounts.contact',
			actions: update,
		},
		Fields: {
			TIN: {
				resource: 'accounts.fields.tin',
				actions: update,
			},
			DOT: {
				resource: 'accounts.fields.dot',
				actions: update,
			},
			BusinessName: {
				resource: 'accounts.fields.businessName',
				actions: update,
			},
			DBA: {
				resource: 'accounts.fields.dba',
				actions: update,
			},
		},
		Flags: {
			resource: 'accounts.flags',
			actions: update,
			RegistrantOnly: {
				resource: 'accounts.flags.registrantOnly',
				actions: update,
			},
		},
		ServiceProviders: {
			resource: 'accounts.serviceProviders',
			actions: all,
		},
	},
	Admin: {
		resource: 'admin',
		actions: read,
		BatchJobs: {
			resource: 'admin.batchJobs',
			actions: read,
		},
		Inventory: {
			resource: 'admin.inventory',
			actions: read,
		},
		Permissions: {
			resource: 'admin.permissions',
			actions: all,
		},
		Users: {
			resource: 'admin.users',
			actions: { ...read, ...write },
		},
	},
	Documents: {
		resource: 'documents',
		actions: { ...read, ...create, ...del },
		Status: {
			resource: 'documents.status',
			actions: update,
		},
	},
	IFTA: {
		resource: 'ifta',
		actions: read,
	},
	IRP: {
		resource: 'irp',
		actions: { ...read, ...write },
		Addresses: {
			Mailing: {
				resource: 'irp.addresses.mailing',
				actions: write,
			},
		},
		Contact: {
			resource: 'irp.contact',
			actions: update,
		},
		ErrorCorrections: {
			resource: 'irp.errorCorrections',
			actions: all,
		},
		Fleets: {
			resource: 'irp.fleets',
			actions: all,
			Fields: {
				EffectiveDate: {
					resource: 'irp.fleets.fields.effectiveDate',
					actions: update,
				},
				ExpirationDate: {
					resource: 'irp.fleets.fields.expirationDate',
					actions: update,
				},
				ActualDistance: {
					resource: 'irp.fleets.fields.actualDistance',
					actions: { override: 'override' },
				},
			},
		},
		Supplements: {
			resource: 'irp.supplements',
			actions: { ...read, ...write, approve: 'approve' },
			Fields: {
				EffectiveDate: {
					resource: 'irp.supplements.fields.effectiveDate',
					actions: update,
				},
				InvoiceComments: {
					resource: 'irp.supplements.fields.invoiceComments',
					actions: update,
				},
			},
		},
		Vehicles: {
			resource: 'irp.vehicles',
			actions: { ...read, ...create, ...update, transfer: 'transfer', ...del },
			Fields: {
				PlateReturn: {
					resource: 'irp.vehicles.fields.plateReturn',
					actions: update,
				},
				PersonalizedPlate: {
					resource: 'irp.vehicles.fields.personalizedPlate',
					actions: update,
				},
				FactoryPrice: {
					resource: 'irp.vehicles.fields.factoryPrice',
					actions: update,
				},
			},
		},
		WeightGroups: {
			resource: 'irp.weightGroups',
			actions: all,
			Fields: {
				VarianceReason: {
					resource: 'irp.weightGroups.fields.varianceReason',
					actions: update,
				},
			},
		},
	},
	History: {
		resource: 'history',
		actions: read,
	},
	Reports: {
		resource: 'reports',
		actions: read,
		Accounting: {
			resource: 'reports.accounting',
			actions: all,
		},
		Admin: {
			resource: 'reports.admin',
			actions: all,
		},
		General: {
			resource: 'reports.general',
			actions: all,
		},
	},
	Refunds: {
		resource: 'refunds',
		actions: read,
	},
	Transmittals: {
		resource: 'transmittals',
		actions: all,
	},
};

export const toPermission = (perm: { resource: string; actions: Record<Action, Action> }): Permission => ({
	resource: perm.resource,
	actions: Object.values(perm.actions),
});

export const noPermissionToast = (t: TFunction, id?: string): ToastProps => ({
	id: id || 'no-permission',
	message: t('errors.403.toast', { ns: 'core' }),
	severity: 'error',
});

export default Permissions;
