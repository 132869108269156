import { Close } from '@mui/icons-material';
import { Box, Drawer, IconButton, Paper, styled, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as IterisLogo } from 'core/images/iteris-logo.svg';
import { useCurrentUser } from 'modules/admin/modules/users/components/CurrentUserContext';
import { PropsWithChildren, createRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar, { AppBarProps } from './AppBar';
import { NestedNavigation, NestedNavigationProps } from './NestedNavigation';

const MENU_WIDTH = 220;
const MENU_TRANSITION = 'all 0.25s cubic-bezier(0.770, 0.000, 0.175, 1.000)';

const SidebarContent = styled(Box)(({ theme }) => ({
	backgroundColor: theme.navbar?.sidebar?.backgroundColor || theme.navbar?.backgroundColor?.hover || undefined,
	width: MENU_WIDTH,
	transition: MENU_TRANSITION,
	overflow: 'auto',
	flexDirection: 'column',
}));

const MainContent = styled(Box)({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	overflow: 'auto',
	transition: MENU_TRANSITION,
});

const DrawerHeader = styled(Paper)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 2),
	boxSizing: 'border-box',
	borderRadius: 0,
	zIndex: 999,
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'space-between',
}));

type LayoutProps = Pick<AppBarProps, 'modules' | 'helpRoute' | 'getUserType'> & {
	menuItems: NestedNavigationProps['items'];
	components?: {
		logo?: JSX.Element;
		sidebar?: {
			footer?: JSX.Element;
		};
	};
};

export default function Layout({
	modules,
	helpRoute,
	getUserType,
	children,
	menuItems,
	components,
}: PropsWithChildren<LayoutProps>) {
	// Hooks
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const { currentUser } = useCurrentUser();

	// State
	const [open, setOpen] = useState(false);
	const mainContentRef = createRef<HTMLDivElement>();

	// If the sidebar should be open by default
	useEffect(() => {
		if (theme.navbar?.sidebar?.initialState === 'open' && !isMobile) {
			setOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// If we go from desktop to mobile, close the sidebar
	useEffect(() => {
		if (isMobile) {
			setOpen(false);
		}
	}, [isMobile]);

	return (
		<>
			<AppBar
				modules={modules}
				helpRoute={helpRoute}
				open={open}
				scrollRef={mainContentRef}
				logoComponent={components?.logo}
				getUserType={getUserType}
				setOpen={setOpen}
			/>
			<Box display="flex" flex={1} flexDirection="row" height="100%">
				{theme.navbar?.mode === 'vertical' && (
					<SidebarContent
						sx={{
							display: { xs: 'none', md: 'flex' },
							transform: open ? 'translateX(0)' : `translateX(${MENU_WIDTH * -1}px)`,
						}}
					>
						<DrawerHeader elevation={0} />
						<NestedNavigation
							items={menuItems}
							currentUser={currentUser}
							sx={{ flex: 1, overflow: components && components.sidebar?.footer ? 'auto' : undefined }}
						/>
						{components && components.sidebar?.footer}
					</SidebarContent>
				)}
				<MainContent
					sx={{
						transform: { xs: 'none', md: open ? '0' : `translateX(${MENU_WIDTH * -1}px)` },
						marginRight: { xs: 'none', md: open ? '0' : `${MENU_WIDTH * -1}px` },
					}}
					ref={mainContentRef}
				>
					<DrawerHeader elevation={0} />
					{children}
				</MainContent>
			</Box>

			{isMobile && (
				<Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
					<DrawerHeader
						elevation={2}
						sx={{ backgroundColor: theme.navbar?.backgroundColor?.main || theme.palette.primary.main }}
					>
						<Link to="/">{components?.logo || <IterisLogo height={28} />}</Link>

						<IconButton
							aria-label="menu-icon"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							sx={{ mr: -1.5 }}
							onClick={() => setOpen(!open)}
						>
							<Close sx={{ color: theme.navbar?.backgroundColor?.hover || theme.palette.primary.contrastText }} />
						</IconButton>
					</DrawerHeader>
					<Box
						flex={1}
						sx={{
							backgroundColor:
								theme.navbar?.sidebar?.backgroundColor || theme.navbar?.backgroundColor?.hover || undefined,
						}}
					>
						<NestedNavigation items={menuItems} currentUser={currentUser} />
					</Box>
				</Drawer>
			)}
		</>
	);
}
