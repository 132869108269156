import { createFilterOptions } from '@mui/material';
import { Namespace, TFunction } from 'i18next';
import * as Yup from 'yup';

export interface State {
	id: number;
	name: string;
	code: string;
	countryId: number;
}

export interface StateFields {
	id: number;
}

export const statesWithDivider = (states: State[]) =>
	states.reduce((acc: (State & { divider?: boolean })[], s) => {
		// Changing country, add divider
		if ((acc[acc.length - 1] as State)?.countryId !== s.countryId && acc[acc.length - 1]) {
			acc.push({ ...s, id: -1 * s.countryId, code: `divider-${s.countryId}`, divider: true });
		}

		acc.push(s);
		return acc;
	}, []);

export const stateCountyRequired = (state: string) => {
	return ['IA'].includes(state.toUpperCase());
};

export const statePlateFormat = (stateCode: string) => {
	// add state regex patterns here; add custom error messages to
	// add_vehicle en.json vehicle.plate.state.validations
	const formats: Record<string, RegExp> = {
		IA: /^[A-Z0-9]{2,7}$/,
	};

	if (!formats[stateCode])
		return {
			regex: /^[A-Z0-9]+$/,
			stateCode: 'default',
		};

	return {
		regex: formats[stateCode],
		stateCode,
	};
};

export const stateFilterOptions = createFilterOptions({
	matchFrom: 'start',
	stringify: (o: State) => `${o.code} - ${o.name}`,
});

export const stateSelectOptions = {
	getOptionKey: (s: State) => s.id,
	getOptionLabel: (s: State) => `${s.code} - ${s.name}`,
};

export const StateValidationSchema = ({ t }: { t: TFunction<Namespace> }) => ({
	id: Yup.number().required(t('data.validation.required', { ns: 'core' })),
});
