import { isEqual, numberFormat } from 'core/services/intl';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import Fleet from './Fleet';
import LookupValue, { LookupValueID, LookupValueValidationSchema } from './LookupValue';
import { OperatingJurisdiction, OperatingJurisdictionFields } from './OperatingJurisdiction';
import Tracking from './Tracking';

export const WeightGroupMinGrossWeight = 2_000;
export const WeightGroupMaxGrossWeight = 999_999;
export const WeightGroupTrailerMaxWeight = 34_000;

export const WeightGroupNew = 'new';

export default interface WeightGroup {
	id: number;
	key: string;
	number: number;
	type: LookupValue;
	description: string;
	maxGrossWeight: number;
	active: boolean;
	activeVehicles: number;
	pendingVehicles: number;
	status: LookupValue;
	tracking: Tracking;
	varianceReason?: string;
	operatingJurisdictions: OperatingJurisdiction[];
	fleet?: Fleet;
}

export enum WeightGroupType {
	PowerUnit = 'P',
	TrailerUnit = 'T',
}

export enum WeightGroupStatus {
	Active = 'A',
	Inactive = 'I',
	Processing = 'P',
}

export interface WeightGroupIncludes {
	fleet?: boolean;
	operatingJurisdictions?: boolean;
}

export interface WeightGroupFields {
	description?: string;
	maxGrossWeight?: number;
	operatingJurisdictions?: OperatingJurisdictionFields[];
	type?: LookupValueID | null;
	varianceReason?: string;
}

export interface WeightGroupChangeSupplementDetails {
	weightGroup: WeightGroup;
}

export function WeightGroupValidationSchema(creating: boolean, t: TFunction) {
	const maxGrossWeightBetween = t('data.validation.between', {
		ns: 'core',
		min: numberFormat(WeightGroupMinGrossWeight),
		max: `${numberFormat(WeightGroupMaxGrossWeight)} ${t('data.units.pounds', { ns: 'core' })}`,
	});

	return Yup.object<WeightGroupFields>().shape({
		type: creating
			? Yup.object()
					.shape(LookupValueValidationSchema)
					.required(t('data.validation.required', { ns: 'core' }))
			: Yup.object().strip(),
		description: Yup.string().required(t('data.validation.required', { ns: 'core' })),
		maxGrossWeight: Yup.number()
			.required(t('data.validation.required', { ns: 'core' }))
			.min(WeightGroupMinGrossWeight, maxGrossWeightBetween)
			.max(WeightGroupMaxGrossWeight, maxGrossWeightBetween),
		operatingJurisdictions: Yup.array()
			.defined()
			.of(
				Yup.object().shape({
					jurisdiction: Yup.object().shape({
						id: Yup.number().defined(),
					}),
					baseWeight: Yup.number()
						.defined()
						.transform((v) => (!v ? 0 : v))
						.default(0)
						.min(0, t('data.validation.required', { ns: 'core' })),
				}),
			),

		varianceReason: Yup.string().when('operatingJurisdictions', {
			is: (operatingJurisdictions: OperatingJurisdictionFields[]) => {
				const weights = operatingJurisdictions.map((oj) => oj.baseWeight);
				const maxWeight = Math.max(...weights);
				const minWeight = Math.min(...weights);
				const difference = maxWeight - minWeight;

				return difference > WeightGroupMaxGrossWeight * 0.1;
			},
			then: (schema) => schema.required(t('data.validation.required', { ns: 'core' })),
			otherwise: (schema) => schema.strip(),
		}),
	});
}

export function weightGroupHasChanged(wg1?: WeightGroup, wg2?: WeightGroup) {
	// Check weight group equality
	const wgf1 = {
		number: wg1?.number,
		type: wg1?.type,
		description: wg1?.description,
		maxGrossWeight: wg1?.maxGrossWeight,
	};

	const wgf2 = {
		number: wg2?.number,
		type: wg2?.type,
		description: wg2?.description,
		maxGrossWeight: wg2?.maxGrossWeight,
	};

	return !isEqual(wgf1, wgf2);
}
