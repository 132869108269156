import { Button } from '@mui/material';
import ClearFleetError from 'core/components/ClearFleetError';
import { useAPI } from 'core/hooks';
import { ApiError } from 'core/services/api';
import SupplementsService, { CreateRestriction } from 'modules/irp/modules/supplements/api/SupplementsService';
import SupplementCreator from 'modules/irp/modules/supplements/components/SupplementCreator';
import RenewalPaths from 'modules/irp/modules/supplements/modules/renewal/routes/paths';
import SupplementPaths from 'modules/irp/modules/supplements/routes/paths';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { SupplementType } from 'types/Supplement';

export default function RenewalSupplementCreator() {
	// Hooks
	const { t } = useTranslation(['irp/supplements/renewal', 'irp/supplements']);
	const [{ fleetId }] = useTypedSearchParams(RenewalPaths);
	const navigate = useNavigate();

	// Services
	const supplementsService = useAPI(SupplementsService);

	// State
	const [error, setError] = useState<Error | null>(null);
	const hasRestrictions = (() => {
		if (!(error instanceof ApiError)) return false;
		if (!error.details) return false;

		const details = error.details as { restrictions: CreateRestriction[] };
		return details.restrictions.length > 0;
	})();

	// New supplement, create it
	const create = useCallback(() => {
		// No account key, redirect to the main page
		if (!fleetId) return navigate(SupplementPaths.buildPath({}));

		// Create supplement
		return supplementsService
			.create({
				type: SupplementType.Renewal,
				renewal: {
					fleet: { id: fleetId },
				},
			})
			.then((supplement) =>
				// Replace the URL to prevent a second account creation attempt
				navigate(RenewalPaths.buildPath({ supplementKey: supplement.key }), { replace: true }),
			)
			.catch(setError);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fleetId]);

	// Error'd
	if (error)
		return (
			<ClearFleetError error={{ ...error, messages: [t('create.error'), error.message] }}>
				{hasRestrictions ? (
					<Button variant="contained" onClick={() => navigate(SupplementPaths.buildPath({}))}>
						{t('title', { ns: 'irp/supplements' })}
					</Button>
				) : null}
			</ClearFleetError>
		);

	// New supplement, create
	return <SupplementCreator message={t('create.loading')} create={create} />;
}
