/* eslint-disable no-use-before-define */
import { SxProps, Theme } from '@mui/material';
import List from '@mui/material/List';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import User from 'types/User';
import { NestedNavigationItem, NestedNavigationItemProps } from './NestedNavigationItem';

// NestedNavigation properties
export type NestedNavigationProps = {
	// NestedNavigation items
	items: NestedNavigationItemProps[];

	// Additional styling
	sx?: SxProps<Theme>;

	// Current user
	currentUser: User;
};

// NestedNavigation is a recursive component that renders a list of items
// and their children. It uses the NestedNavigationContext to keep track
// of the active item without having to pass it down through props.
export function NestedNavigation({ items, sx, currentUser }: NestedNavigationProps) {
	// Hooks
	const location = useLocation();

	// State
	const currentModule = items.find((item) =>
		item.items?.find((child) => location.pathname.indexOf(child.to || '') === 0),
	)?.id;
	const [active, setActive] = useState<string | null>(currentModule || null);

	return (
		<List sx={sx}>
			{items
				.filter((item) => !item.isVisible || item.isVisible({ currentUser }))
				.map((top) => (
					<NestedNavigationItem
						key={top.id}
						id={top.id}
						label={top.label}
						to={top.to}
						permission={top.permission}
						items={top.items}
						depth={0}
						active={active}
						currentUser={currentUser}
						isVisible={top.isVisible}
						onClick={(item) => {
							// If the item has children, open/close it
							if (item.items) {
								setActive(active !== item.id ? item.id || null : null);
							}
						}}
					/>
				))}
		</List>
	);
}
