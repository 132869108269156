import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from 'core/components/LoadingButton';
import { useTranslation } from 'react-i18next';

interface AccountFooterProps {
	onPrevious: () => void;
	onNext: () => void | Promise<void>;
	nextLabel?: string;
}

export default function AccountFooter({ onPrevious, onNext, nextLabel }: AccountFooterProps) {
	// Hooks
	const { t } = useTranslation('irp/supplements/add_vehicle');

	return (
		<Box display="flex" justifyContent="space-between" alignItems="center">
			<Button variant="outlined" onClick={onPrevious}>
				{t('buttons.close', { ns: 'core' })}
			</Button>
			<LoadingButton variant="contained" onClick={onNext}>
				{nextLabel || t('buttons.next', { ns: 'core' })}
			</LoadingButton>
		</Box>
	);
}
