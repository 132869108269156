import { Box, styled } from '@mui/material';
import { Loader } from 'core/components';
import { useTitle } from 'core/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { route } from 'react-router-typesafe-routes/dom';

const ScribeHelpDocsUrl = 'https://scribehow.com/page-embed/ClearFleet_Training_Documents__YcCVXmXkTfmUbZTVM85HHQ';

export const HelpPath = route('help');

const Iframe = styled('iframe')({
	flex: 1,
	border: 0,
});

export default function Help() {
	// Hooks
	const { t } = useTranslation('home');
	useTitle(`${t('help.title')} - ${t('title', { ns: 'core' })}`);

	// State
	const [loading, setLoading] = useState(true);

	return (
		<Box display="flex" flexDirection="column" flex={1} position="relative">
			<Iframe title={t('help.iframe.title')} src={ScribeHelpDocsUrl} onLoad={() => setLoading(false)} allowFullScreen />
			{loading && (
				<Loader sx={{ position: 'absolute', backgroundColor: '#fff', top: 0, left: 0, right: 0, bottom: 0 }} />
			)}
		</Box>
	);
}
