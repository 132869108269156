import { Box, Button, Divider, Typography } from '@mui/material';
import CertifyCheckbox from 'core/components/CertifyCheckbox';
import ClearFleetForm, { Field, SelectField } from 'core/components/ClearFleetForm';
import { phoneNumberFormat, tinFormat } from 'core/services/intl';
import { useFormik } from 'formik';
import { useClient } from 'modules/irp/modules/supplements/providers/ClientProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Address, AddressValidationSchema } from 'types/Address';
import { CarrierFields } from 'types/Carrier';
import { Contact, ContactValidationSchema } from 'types/Contact';
import { State, stateCountyRequired, stateSelectOptions, statesWithDivider } from 'types/State';
import * as Yup from 'yup';

interface CarrierForm {
	einTin: string;
	dotNumber: number | null;
	name: string;
	dbaName?: string;
	contact: Contact | null;
	addresses: {
		business?: Address | null;
		mailing?: Address | null;
	};
	accountFlags: {
		registrantOnly: boolean | null;
	};
	certified: boolean;
}

export interface AccountDetailsFormProps {
	onFormik: (formik: ReturnType<typeof useFormik<CarrierForm>>) => void;
	onSubmit: (data: CarrierFields) => Promise<void> | void;
}

export default function AccountDetailsForm({ onFormik, onSubmit }: AccountDetailsFormProps) {
	const { t } = useTranslation('accounts');
	const { client, states } = useClient();

	const validationSchema = Yup.object<CarrierForm>().shape({
		accountFlags: Yup.object().shape({
			registrantOnly: Yup.boolean().required(t('data.validation.required', { ns: 'core' })),
		}),
		einTin: Yup.string()
			.required(t('data.validation.required', { ns: 'core' }))
			.transform((v) => (v ? v.replace(/[^0-9]/g, '') : '')) // Remove non-numeric characters
			.length(9, t('data.validation.len.exactly', { ns: 'core', len: 9 })),
		dotNumber: Yup.lazy((_, { context }) => {
			if (context && (context as CarrierForm).accountFlags.registrantOnly === true) {
				return Yup.number().strip();
			}
			return Yup.number().required(t('data.validation.required', { ns: 'core' }));
		}),
		name: Yup.string().required(t('data.validation.required', { ns: 'core' })),
		dbaName: Yup.string()
			.optional()
			.transform((v) => v || undefined),
		contact: Yup.object()
			.shape(ContactValidationSchema({ t }))
			.required(t('data.validation.required', { ns: 'core' })),
		addresses: Yup.object().shape({
			business: Yup.object().shape(AddressValidationSchema({ t })),
			mailing: Yup.object().shape(AddressValidationSchema({ t })).nullable(),
		}),
		certified: Yup.boolean().isTrue(t('data.validation.form_incomplete', { ns: 'core' })),
	});

	const formik = useFormik<CarrierForm>({
		initialValues: {
			accountFlags: {
				registrantOnly: null,
			},
			einTin: '',
			dotNumber: null,
			name: '',
			dbaName: '',
			contact: {
				name: '',
				email: '',
				phone: '',
				phoneExtension: null,
				fax: '',
			},
			addresses: {
				business: {
					line1: '',
					city: '',
					state: client?.baseJurisdiction || null,
					postalCode: '',
					country: '',
				},
				mailing: null,
			},
			certified: false,
		},
		validationSchema,
		onReset: () => {
			formik.validateForm();
		},
		onSubmit: async (data) => {
			const { certified: _, ...stripped } = data;
			const fields: CarrierFields = validationSchema.cast(stripped, { context: data, stripUnknown: true });
			return onSubmit(fields);
		},
	});

	// Pass form handler up to parent
	useEffect(() => {
		onFormik(formik);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onFormik]);

	// When client changes (e.g. after loading), update the state
	useEffect(() => {
		if (!client) return;
		formik.setFieldValue('addresses.business.state', client.baseJurisdiction);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client]);

	// when mailing addr state updated, re-validate postal code by country
	useEffect(() => {
		if (!formik.values.addresses.mailing) return;
		formik.validateField('addresses.mailing.postalCode');
		onFormik(formik);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.addresses.mailing?.state]);

	const registrantOnlyField: Field<CarrierForm>[] = [
		{
			type: 'radio',
			name: 'accountFlags.registrantOnly',
			label: t('carrier.registrant_only.prompt', { ns: 'data' }),
			required: true,
			options: [
				{ label: t('data.yes', { ns: 'core' }), value: 'true' },
				{ label: t('data.no', { ns: 'core' }), value: 'false' },
			],
			getValue: (v) => String(v.accountFlags.registrantOnly),
		},
	];

	const einField: Field<CarrierForm> = {
		type: 'text',
		name: 'einTin',
		label: t('carrier.ein_tin', { ns: 'data' }),
		max: 10,
		required: true,
		getValue: (v) => tinFormat(v.einTin) || '',
	};

	const dotField: Field<CarrierForm> = {
		type: 'number',
		name: 'dotNumber',
		label: t('carrier.usdot', { ns: 'data' }),
		max: 8,
		required: !formik.values?.accountFlags.registrantOnly,
		getValue: (v) => (v.dotNumber ? String(v.dotNumber) : ''),
	};

	const accountInfoFields: Field<CarrierForm>[] =
		formik.values.accountFlags?.registrantOnly === false ? [einField, dotField] : [einField];

	const businessInformationFields: Field<CarrierForm>[] = [
		{
			type: 'text',
			name: 'name',
			label: t('carrier.business_name', { ns: 'data' }),
			max: 100,
			required: true,
			getValue: (v) => v.name || '',
		},

		{
			type: 'text',
			name: 'dbaName',
			label: t('carrier.dba_name', { ns: 'data' }),
			max: 100,
			getValue: (v) => v.dbaName || '',
		},

		{
			type: 'text',
			name: 'contact.name',
			label: t('carrier.contact_name', { ns: 'data' }),
			max: 100,
			required: true,
			getValue: (v) => v.contact?.name || '',
		},

		{
			type: 'text',
			name: 'contact.email',
			label: t('carrier.contact_email', { ns: 'data' }),
			max: 100,
			required: true,
			getValue: (v) => v.contact?.email || '',
		},

		{
			type: 'text',
			name: 'contact.phone',
			label: t('carrier.phone', { ns: 'data' }),
			max: 14,
			required: true,
			getValue: (v) => phoneNumberFormat(v.contact?.phone || ''),
		},

		{
			type: 'number',
			name: 'contact.phoneExtension',
			label: t('carrier.phone_extension', { ns: 'data' }),
			max: 9,
			getValue: (v) => (v.contact?.phoneExtension ? String(v.contact.phoneExtension) : ''),
		},

		{
			type: 'text',
			name: 'contact.fax',
			label: t('carrier.fax', { ns: 'data' }),
			max: 14,
			getValue: (v) => phoneNumberFormat(v.contact?.fax || ''),
		},
	];

	const businessAddressFields: Field<CarrierForm>[] = [
		{
			type: 'text',
			name: 'addresses.business.line1',
			label: t('data.fields.street1', { ns: 'core' }),
			max: 100,
			required: true,
			getValue: (v) => v.addresses.business?.line1 || '',
		},

		{
			type: 'text',
			name: 'addresses.business.line2',
			label: t('data.fields.street2', { ns: 'core' }),
			max: 100,
			getValue: (v) => v.addresses.business?.line2 || '',
		},

		{
			type: 'text',
			name: 'addresses.business.county',
			label: t('data.fields.county', { ns: 'core' }),
			max: 100,
			required: stateCountyRequired(formik.values.addresses.business?.state?.code || ''),
			getValue: (v) => v.addresses.business?.county || '',
		},

		{
			type: 'text',
			name: 'addresses.business.city',
			label: t('data.fields.city', { ns: 'core' }),
			max: 50,
			required: true,
			getValue: (v) => v.addresses.business?.city || '',
		},

		{
			type: 'readonly',
			name: 'addresses.business.state',
			label: t('data.fields.state', { ns: 'core' }),
			getValue: (v) => {
				const s = v.addresses.business?.state;
				return s ? stateSelectOptions.getOptionLabel(s) : '';
			},
		},

		{
			type: 'text',
			name: 'addresses.business.postalCode',
			label: t('data.fields.zip', { ns: 'core' }),
			max: 10,
			required: true,
			getValue: (v) => v.addresses.business?.postalCode || '',
		},
	];

	const mailingAddressFields: Field<CarrierForm>[] = [
		{
			type: 'text',
			name: 'addresses.mailing.line1',
			label: t('data.fields.street1', { ns: 'core' }),
			max: 100,
			required: true,
			getValue: (v) => v.addresses.mailing?.line1 || '',
		},

		{
			type: 'text',
			name: 'addresses.mailing.line2',
			label: t('data.fields.street2', { ns: 'core' }),
			max: 100,
			getValue: (v) => v.addresses.mailing?.line2 || '',
		},

		{
			type: 'text',
			name: 'addresses.mailing.county',
			label: t('data.fields.county', { ns: 'core' }),
			max: 100,
			required: stateCountyRequired(formik.values.addresses.mailing?.state?.code || ''),
			getValue: (v) => v.addresses.mailing?.county || '',
		},

		{
			type: 'text',
			name: 'addresses.mailing.city',
			label: t('data.fields.city', { ns: 'core' }),
			max: 50,
			required: true,
			getValue: (v) => v.addresses.mailing?.city || '',
		},

		{
			type: 'select',
			name: 'addresses.mailing.state',
			label: t('data.fields.state', { ns: 'core' }),
			options: statesWithDivider(states),
			getValue: (v) => v.addresses.mailing?.state || null,
			required: true,
			selectProps: {
				...stateSelectOptions,
				onChange: () => {
					setTimeout(() => {
						formik.validateField('addresses.mailing.county');
					}, 1);
				},
			},
		} as SelectField<CarrierForm, State>,

		{
			type: 'text',
			name: 'addresses.mailing.postalCode',
			label: t('data.fields.zip', { ns: 'core' }),
			max: 10,
			required: true,
			getValue: (v) => v.addresses.mailing?.postalCode || '',
		},
	];

	return (
		<Box>
			<Box sx={{ my: 3 }}>
				<ClearFleetForm fields={registrantOnlyField} form={formik} />
			</Box>

			<ClearFleetForm fields={accountInfoFields} form={formik} />

			<Divider />

			<Typography variant="h4" gutterBottom mb={2}>
				{t('details.business_information')}
			</Typography>

			<ClearFleetForm fields={businessInformationFields} form={formik} />

			<Divider />

			<Typography variant="h4" gutterBottom mb={2}>
				{t('details.business_address')}
			</Typography>

			<ClearFleetForm fields={businessAddressFields} form={formik} />

			{!formik.values.addresses.mailing && (
				<Box display="flex" columnGap={1} sx={{ mt: 3 }}>
					<Button
						variant="outlined"
						onClick={() => formik.setFieldValue('addresses.mailing', { state: null })}
						disabled={formik.isSubmitting}
					>
						{t('buttons.add_mailing_address', { ns: 'core' })}
					</Button>
				</Box>
			)}

			{!!formik.values.addresses.mailing && (
				<>
					<Divider />
					<Typography variant="h4" gutterBottom mb={2}>
						{t('details.mailing_address')}
					</Typography>
					<ClearFleetForm fields={mailingAddressFields} form={formik} />
					<Button
						sx={{ mt: 3 }}
						color="error"
						variant="outlined"
						onClick={() => formik.setFieldValue('addresses.mailing', null)}
						disabled={formik.isSubmitting}
					>
						{t('buttons.remove_mailing_address', { ns: 'core' })}
					</Button>
				</>
			)}

			<Divider />

			<CertifyCheckbox
				name="certified"
				checked={formik.values.certified}
				disabled={formik.isSubmitting}
				onChange={formik.handleChange}
				error={!!formik.errors.certified && formik.submitCount > 0}
			/>
		</Box>
	);
}
