import { Info, Warning } from '@mui/icons-material';
import {
	Box,
	Chip,
	CircularProgress,
	Divider,
	FormControlLabel,
	FormHelperText,
	Grid,
	GridProps,
	InputAdornment,
	InputBaseProps,
	InputLabel,
	InputProps,
	FormControl as MuiFormControl,
	Radio,
	RadioGroup,
	Tooltip,
	useTheme,
} from '@mui/material';
import FormAutoComplete, { FormAutoCompleteProps } from 'core/components/FormAutocomplete';
import FormControl, { FormControlProps } from 'core/components/FormControl';
import FormDateField, { FormDateFieldProps } from 'core/components/FormDateField';
import FormInputField from 'core/components/FormInputField';
import FormSelectField, { FormSelectFieldProps, FormSelectOption } from 'core/components/FormSelectField';
import { ApiError } from 'core/services/api';
import dayjs from 'dayjs';
import { FormikValues, getIn, setIn, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SkeletonField from './SkeletonField';

export interface BaseField {
	name: string;
	label: string;
	helperText?: string;
	required?: boolean;
	disabled?: boolean;
	gridProps?: GridProps;
}

export interface TextField<T> extends BaseField {
	type: 'text';
	max?: number;
	getValue(v: T): string;
	tooltip?: string;
	inputProps?: InputProps;
	slotProps?: FormControlProps['slotProps'];
	warning?: string;
	serverValidationField?: string;
}

export interface NumberField<T> extends BaseField {
	type: 'number';
	max?: number;
	getValue(v: T): string;
	tooltip?: string;
	inputProps?: InputProps;
	slotProps?: FormControlProps['slotProps'];
	warning?: string;
	serverValidationField?: string;
}

export interface ReadOnlyField<T>
	extends Pick<TextField<T>, 'name' | 'label' | 'helperText' | 'getValue' | 'inputProps' | 'gridProps' | 'tooltip'> {
	type: 'readonly';
}

export interface PriceField<T> extends BaseField {
	type: 'price';
	max?: number;
	getValue(v: T): string | null;
	tooltip?: string;
	inputProps?: InputProps;
	slotProps?: FormControlProps['slotProps'];
	warning?: string;
	serverValidationField?: string;
}

export interface SelectField<T, V> extends BaseField {
	type: 'select';
	options: FormSelectOption<V>[];
	getValue(v: T): V | null;
	tooltip?: string;
	selectProps: Pick<FormSelectFieldProps<FormSelectOption<V>>, 'getOptionKey' | 'getOptionLabel'>;
	slotProps?: FormSelectFieldProps<V>['slotProps'];
}

export interface DateField<T> extends BaseField {
	type: 'date';
	getValue(v: T): dayjs.Dayjs | null;
	tooltip?: string;
	datePickerProps?: FormDateFieldProps['slotProps']['datePicker'];
}

export interface AutocompleteField<V> extends BaseField {
	type: 'autocomplete';
	value: V | null;
	debounce?: number;
	getOptions(value: string): Promise<V[]>;
	autocompleteProps?: FormAutoCompleteProps<V>['slotProps']['autocomplete'];
}

export interface RadioField<T> extends BaseField {
	type: 'radio';
	options: { label: string; value: string | number }[];
	getValue(v: T): string | number;
	tooltip?: string;
}

export interface DividerField {
	type: 'divider';
	name: string;
	label?: string;
	gridProps?: GridProps;
}

export interface ComponentField {
	type: 'component';
	name: string;
	label?: string;
	tooltip?: string;
	component: JSX.Element | null;
	fullWidth?: boolean;
	gridProps?: GridProps;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Field<T, V = any> =
	| TextField<T>
	| NumberField<T>
	| PriceField<T>
	| ReadOnlyField<T>
	| SelectField<T, V>
	| DateField<T>
	| AutocompleteField<V>
	| RadioField<T>
	| DividerField
	| ComponentField;

export type ServerValidatingState = Record<string, boolean>;

type FieldWithServerValidation<T> = Pick<TextField<T>, 'serverValidationField'>;

export interface ClearFleetFormProps<T extends FormikValues, V extends object = object> {
	fields: Field<T, V>[];
	form: ReturnType<typeof useFormik<T>>;
	loading?: boolean;
	serverValidator?: (v: T) => Promise<ApiError[] | undefined>;
	onValidation?: (s: ServerValidatingState, errs: ApiError[]) => void;
}

export default function ClearFleetForm<T extends FormikValues, V extends object = object>({
	fields,
	form,
	loading: parentLoading,
	serverValidator,
	onValidation,
}: ClearFleetFormProps<T, V>) {
	// Hooks
	const { t } = useTranslation();
	const theme = useTheme();

	// State
	const [serverValidation, setServerValidation] = useState<ApiError[]>([]);
	const [serverValidating, setServerValidating] = useState<ServerValidatingState>({});
	const [serverTimeout, setServerTimeout] = useState<NodeJS.Timeout | null>(null);

	// Computed
	const loading = parentLoading || form.isSubmitting;
	const setServerValidatingField = (name: string, validating: boolean) => {
		setServerValidating((prev) => ({ ...prev, [name]: validating }));
	};

	// Debounce and queue server-side validation
	const queueValidation = async (field: Field<T, V>, values: T) => {
		// Skip if no server-side validation
		const validationField = (field as FieldWithServerValidation<T>).serverValidationField;
		if (!validationField || !serverValidator) return;

		// Debounce validation
		if (serverTimeout) clearTimeout(serverTimeout);

		const validate = async () => {
			setServerValidatingField(validationField, true);
			serverValidator(values)
				// Set errors
				.then((errors) => setServerValidation(errors || []))
				// Re-validate fields that have server validation
				.then(() => {
					fields
						// Filter out fields that have server validation
						.filter((f) => !!(f as FieldWithServerValidation<T>).serverValidationField)
						// Map to the field name and server validation field
						.map((f) => ({
							name: f.name,
							serverValidationField: (f as FieldWithServerValidation<T>).serverValidationField as string,
						}))
						.forEach(({ name, serverValidationField }) => {
							// Validate the field
							setTimeout(() => {
								form.validateField(name);
								setServerValidatingField(serverValidationField, false);
							}, 100);
						});
				});
		};

		// Debounce validation after 500ms
		setServerTimeout(setTimeout(validate, 500));
	};

	// Input change handler
	const handleInputChange: Required<InputBaseProps>['onChange'] = (e) => {
		let v: string | number | boolean | null = e.target.value;
		const field = fields.find((f) => f.name === e.target.name);
		if (!field) return;

		switch (field.type) {
			case 'number':
				// Remove non-numeric characters
				v = v.replace(/[^0-9]/g, '');
				break;
			case 'price':
				// Remove non-numeric characters
				v = v.replace(/[^0-9.]/g, '');
				// Only allow one decimal point
				if (v.split('.').length > 2) v = v.slice(0, -1);
				// Only allow up to two decimal places
				if (v.split('.')[1]?.length > 2) v = v.slice(0, -1);
				if (v === '') {
					v = null;
				}
				break;
			default:
				break;
		}

		// Radio buttons
		if (e.target.type === 'radio') {
			v = e.target.value === 'true';
		}

		if (typeof v === 'undefined') return;
		form.setFieldTouched(e.target.name, true, false);
		form.setFieldValue(e.target.name, v);

		// Server validation, if applicable
		const values = setIn(form.values, e.target.name, v);
		queueValidation(field, values);
	};

	// Is the field full width?
	function isFullWidth(field: Field<T, V>) {
		return ['radio', 'divider'].includes(field.type) || (field.type === 'component' && field.fullWidth);
	}

	// Render
	function renderField(field: Field<T, V>) {
		const hasError = form.submitCount > 0 && !!getIn(form.errors, field.name);
		const validationField = (field as FieldWithServerValidation<T>).serverValidationField;

		const serverValidationIcon = (() => {
			const wrapIcon = (icon: JSX.Element) => (
				<Box display="flex" alignItems="center">
					{icon}
				</Box>
			);

			// Validating
			if (validationField && serverValidating[validationField]) return wrapIcon(<CircularProgress size={20} />);

			// Has error
			if (validationField && serverValidation.some((v) => v.field === validationField))
				return wrapIcon(<Warning color="error" />);

			return null;
		})();

		switch (field.type) {
			case 'text':
			case 'number':
			case 'price': {
				const {
					type,
					label,
					name,
					helperText,
					required,
					disabled,
					max,
					getValue,
					warning,
					inputProps,
					slotProps,
					tooltip,
				} = field;

				return (
					<FormInputField
						label={label}
						name={name}
						tooltip={tooltip}
						helperText={
							getIn(form.errors, name) ||
							warning ||
							helperText ||
							(required && t('data.validation.required')) || <span>&nbsp;</span>
						}
						slotProps={{
							...slotProps,
							formControl: {
								...slotProps?.formControl,
								error: hasError || slotProps?.formControl?.error,
								color: warning ? 'warning' : undefined,
								focused: warning ? true : undefined,
							},
							input: {
								// Allow overriding
								endAdornment: serverValidationIcon,

								// Default props
								...(inputProps || ({} as InputProps)),

								// Non-override props
								value: getValue(form.values),
								disabled: disabled || loading || field.inputProps?.disabled,
								inputProps: {
									maxLength: max,
								},
								startAdornment: type === 'price' ? <InputAdornment position="start">$</InputAdornment> : null,
								onChange: (e) => {
									handleInputChange(e);
									if (inputProps?.onChange) inputProps.onChange(e);
								},
							},
						}}
					/>
				);
			}
			case 'readonly': {
				const { name, label, getValue, inputProps, tooltip } = field;
				if (loading && getValue(form.values) === undefined)
					return (
						<FormControl name={name} label={label}>
							<SkeletonField loading />
						</FormControl>
					);

				return (
					<FormInputField
						name={name}
						tooltip={tooltip}
						label={label}
						helperText={hasError ? getIn(form.errors, name) : '\u00A0'}
						slotProps={{
							formControl: { error: hasError },
							input: {
								...inputProps,
								value: getValue(form.values) || '\u2014',
								readOnly: true,
							},
						}}
					/>
				);
			}
			case 'select': {
				const { label, name, helperText, required, disabled, options, getValue, tooltip, selectProps, slotProps } =
					field;
				return (
					<FormSelectField
						label={label}
						name={name}
						helperText={
							getIn(form.errors, name) ||
							helperText ||
							(required && t('data.validation.required')) || <span>&nbsp;</span>
						}
						tooltip={tooltip}
						value={getValue(form.values)}
						options={options}
						onChange={(v) => {
							form.setFieldTouched(name, true, false);
							form.setFieldValue(name, v, false);

							// Validate the form after the select has been changed
							setTimeout(() => form.validateField(name), 1);
						}}
						getOptionKey={selectProps.getOptionKey}
						getOptionLabel={selectProps.getOptionLabel}
						slotProps={{
							...slotProps,
							formControl: {
								...slotProps?.formControl,
								error: hasError,
							},
							autocomplete: {
								...slotProps?.autocomplete,
								disabled: disabled || loading,
								onKeyDown: (e) => {
									if (slotProps?.autocomplete?.onKeyDown) slotProps.autocomplete.onKeyDown(e);

									// Tab key, move to next field after re-render
									// If holding shift, allow going back to previous field
									if (e.code !== 'Tab' || e.shiftKey) return;

									setTimeout(() => {
										// Try and focus next input
										try {
											(e.target as HTMLInputElement)
												.closest('.MuiGrid-item')
												?.nextElementSibling?.querySelector('input')
												?.focus();
										} catch (err) {
											// Do nothing
										}
									}, 1);
								},
							},
						}}
					/>
				);
			}
			case 'date': {
				const { label, name, helperText, required, disabled, tooltip, getValue, datePickerProps } = field;
				return (
					<FormDateField
						label={label}
						name={name}
						tooltip={tooltip}
						helperText={
							getIn(form.errors, name) ||
							helperText ||
							(required && t('data.validation.required')) || <span>&nbsp;</span>
						}
						getError={() => getIn(form.errors, name)}
						slotProps={{
							formControl: {
								error: hasError,
							},
							inputLabel: {
								shrink: true,
							},
							datePicker: {
								...datePickerProps,
								value: getValue(form.values),
								onChange: (v) => {
									const year = v?.year();
									const month = (v?.month() || 0) + 1;
									const day = v?.date();

									form.setFieldTouched(name, true, false);
									form.setFieldValue(name, year && month && day ? { year, month, day } : null, false);
									setTimeout(() => form.validateField(name), 1);
								},
								disabled: disabled || loading,
							},
						}}
					/>
				);
			}
			case 'autocomplete': {
				const { label, name, helperText, required, disabled, value, debounce, getOptions, autocompleteProps } = field;
				return (
					<FormAutoComplete
						label={label}
						name={name}
						helperText={
							getIn(form.errors, name) ||
							helperText ||
							(required && t('data.validation.required')) || <span>&nbsp;</span>
						}
						debounce={debounce}
						getOptions={getOptions}
						value={value}
						onChange={(v) => {
							form.setFieldValue(name, v, false);
							setTimeout(() => form.validateField(name), 1);
						}}
						slotProps={{
							formControl: {
								error: hasError,
							},
							autocomplete: {
								...autocompleteProps,
								selectOnFocus: true,
								autoHighlight: true,
								disabled: disabled || autocompleteProps?.disabled || loading,
							},
						}}
					/>
				);
			}
			case 'radio': {
				const { label, name, helperText, options, required, disabled, getValue, tooltip } = field;
				return (
					<Grid container gap={3} alignItems="center">
						<Grid item xs={12} sm={8} md={7} lg={5}>
							<InputLabel sx={{ whiteSpace: 'normal' }}>
								{label}
								{tooltip && (
									<Tooltip title={tooltip} color="primary">
										<Info fontSize="small" sx={{ ml: 0.5, verticalAlign: 'text-bottom' }} />
									</Tooltip>
								)}
							</InputLabel>
							<FormHelperText variant="outlined" error={hasError}>
								{getIn(form.errors, name) || helperText || (required && t('data.validation.required'))}
							</FormHelperText>
						</Grid>

						<Grid item flex={{ xs: 1, md: 'unset' }} textAlign="right">
							<MuiFormControl error={hasError} fullWidth={false}>
								<RadioGroup row name={name} value={getValue(form.values)} onChange={handleInputChange}>
									{options.map((opt) => (
										<FormControlLabel
											key={`${opt.label}:${opt.value}`}
											value={opt.value}
											control={<Radio disabled={loading} />}
											label={opt.label}
											disabled={disabled || loading}
											slotProps={{
												typography: {
													color: hasError ? `${theme.palette.error.main} !important` : undefined,
												},
											}}
										/>
									))}
								</RadioGroup>
							</MuiFormControl>
						</Grid>
					</Grid>
				);
			}
			case 'divider': {
				const { label } = field;
				return <Divider sx={{ flex: 1 }}>{label && <Chip label={label} />}</Divider>;
			}
			case 'component': {
				const { label } = field;
				return (
					<>
						{label && <InputLabel>{label}</InputLabel>}
						{field.component}
					</>
				);
			}
			default:
				return null;
		}
	}

	// Bubble up validation state if one or more fields are validating
	useEffect(() => {
		onValidation?.(serverValidating, serverValidation);
	}, [onValidation, serverValidating, serverValidation]);

	return (
		<Grid container columnSpacing={12} rowSpacing={3}>
			{fields.map((field) => (
				<Grid
					key={field.name}
					xs={12}
					sm={!isFullWidth(field) ? 6 : 12}
					md={!isFullWidth(field) ? 4 : 12}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...field.gridProps}
					container={isFullWidth(field)}
					item
				>
					{renderField(field)}
				</Grid>
			))}
		</Grid>
	);
}
